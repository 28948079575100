import * as turf from "@turf/turf";

function simplifyCoordinates(obj) {
  let simplifiedObj = Object.assign({}, obj); // Create a shallow copy of the object

  if (simplifiedObj.geometry && simplifiedObj.geometry.coordinates) {
    // Flatten the nested coordinates array
    const flattenedCoordinates = simplifiedObj.geometry.coordinates.flat();
    simplifiedObj.geometry = {
      ...simplifiedObj.geometry,
      coordinates: flattenedCoordinates.map((coord) => ({
        lat: coord[1],
        lng: coord[0],
      })),
    };
  }

  return simplifiedObj;
}

function convertCoordsToObjArray(coords) {
  return coords.map((coord) => ({ lat: coord[1], lng: coord[0] }));
}

function convertObjArrayToCoords(objArray) {
  return objArray.map((coord) => [coord.lng, coord.lat]);
}

function revertCoordinates(simplifiedObj) {
  let originalObj = Object.assign({}, simplifiedObj); // Create a shallow copy of the object

  if (originalObj.geometry && originalObj.geometry.coordinates) {
    // Map the simplified coordinates back to the original nested array format
    originalObj.geometry = {
      ...originalObj.geometry,
      coordinates: [
        simplifiedObj.geometry.coordinates.map((coord) => [
          coord.lng,
          coord.lat,
        ]),
      ],
    };
  }

  return originalObj;
}

function findSectorContainingPoints(sectors, point1, point2) {
  return sectors.filter((sector) => {
    const polygon = turf.polygon([sector.sector.concat([sector.sector[0]])]); // Ensure the polygon is closed
    const pointFeature1 = turf.point([point1.lng, point1.lat]);
    const pointFeature2 = turf.point([point2.lng, point2.lat]);

    // Check if both points are in the sector
    return (
      turf.booleanPointInPolygon(pointFeature1, polygon) &&
      turf.booleanPointInPolygon(pointFeature2, polygon)
    );
  });
}

export {
  simplifyCoordinates,
  revertCoordinates,
  convertCoordsToObjArray,
  convertObjArrayToCoords,
  findSectorContainingPoints,
};
