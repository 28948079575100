import React from "react";
import { Marker } from "react-map-gl";
import HomeIcon from "@mui/icons-material/Home";

// Props could include latitude, longitude, and other marker-specific props
const PickupMarker = ({ lat, lng, ...props }) => {
  return (
    <Marker latitude={lat} longitude={lng} {...props}>
      <div>
        <HomeIcon color={"success"} />
      </div>
    </Marker>
  );
};

export default React.memo(PickupMarker);
