import React, { useEffect, useState, useRef, useCallback } from "react";
import { Marker, Popup, Source, Layer } from "react-map-gl";
import { useDispatch } from "react-redux";
import { setActiveAsset, setActiveBoat } from "../data/slices/mapSlice";
import { boatApi } from "../api/boat";
import PlannedPath from "./PlannedPath";
import * as turf from "@turf/turf";
export default function Boat({ boatID, viewport }) {
  const dispatch = useDispatch();
  const [boat, setBoat] = useState(null);
  const [circle, setCircle] = useState(null);
  const [showPopup, setShowPopup] = useState(false);
  const markerRef = useRef();

  function decodeOSHealthStatus(status) {
    return {
      missionManager: !!(status & (1 << 0)),
      vehicleInterface: !!(status & (1 << 1)),
      redisInterface: !!(status & (1 << 2)),
      pathPlanner: !!(status & (1 << 3)),
      conflictDetection: !!(status & (1 << 4)),
      zoneManager: !!(status & (1 << 5)),
      trackManager: !!(status & (1 << 6)),
      currentBehavior: !!(status & (1 << 7)),
      // Bits 8-15 are considered reserved in your case
    };
  }

  function decodeHealthSummary(status) {
    return {
      navSolution: !!(status & (1 << 0)),
      battery: !!(status & (1 << 1)),
      motor: !!(status & (1 << 2)),
      blosRadio: !!(status & (1 << 3)),
      losRadio: !!(status & (1 << 4)),
      camera: !!(status & (1 << 5)),
      payload: !!(status & (1 << 6)),
      havocOS: !!(status & (1 << 7)),
      // Bits 8-15 are considered reserved
    };
  }

  function checkSystemHealth(systemStatus) {
    // Extract the keys where the value is false.
    const unhealthyComponents = Object.keys(systemStatus).filter(
      (key) => !systemStatus[key]
    );

    if (unhealthyComponents.length === 0) {
      // If all components are healthy
      return "OS healthy";
    } else {
      // If there are any unhealthy components, return a div with their names.
      return (
        <div>
          {unhealthyComponents.map((component, index) => (
            <div key={index}>{component}</div>
          ))}
        </div>
      );
    }
  }

  function healthSummary(systemStatus) {
    // Extract the keys where the value is false.
    const unhealthyComponents = Object.keys(systemStatus).filter(
      (key) => !systemStatus[key]
    );

    if (unhealthyComponents.length === 0) {
      // If all components are healthy
      return "OS healthy";
    } else {
      // If there are any unhealthy components, return a div with their names.
      return (
        <div>
          {unhealthyComponents.map((component, index) => (
            <div key={index}>{component}</div>
          ))}
        </div>
      );
    }
  }

  // Assuming you're using React, you would use it like so in your component's render method:
  // {healthSummary(healthStatus)}

  const { data: boatString, isLoading } =
    boatApi.endpoints.getBoatByID.useQuery(boatID, {
      pollingInterval: 1000,
    });

  useEffect(() => {
    if (boatString) {
      // console.log("boat string ", boatString);
      // const [latitude, longitude, heading, vNorth, vEast] =
      //   boatString.status.map(parseFloat);

      const speed =
        Math.sqrt(boatString.status.v_n ** 2 + boatString.status.v_e ** 2) *
        1.94384;

      setBoat({
        latitude: boatString.status.lat,
        longitude: boatString.status.lon,
        heading: boatString.status.hdg,
        cloudStatus: boatString.cloud_status,
        geofence: boatString.geofence,
        route: boatString.route,
        avoidZones: boatString.avoidzones,
        speed,
        os_health: decodeOSHealthStatus(boatString.os_health),
        health_summary: decodeHealthSummary(boatString.health_summary),
        playID: boatString?.play_id,
        taskID: boatString?.task_id,
      });
    }
  }, [boatString]);

  useEffect(() => {
    const options = { steps: 64, units: "nauticalmiles" };
    if (boat) {
      setCircle(turf.circle([boat.longitude, boat.latitude], 3, options));
    }
  }, [boat]);

  const togglePopup = useCallback(() => {
    setShowPopup((prevShowPopup) => !prevShowPopup);
    if (!showPopup) {
      dispatch(setActiveAsset(boatID));
      dispatch(setActiveBoat(boat));
    }
  }, [dispatch, boatID, showPopup, boat]);

  if (isLoading) {
    return <div>Loading...</div>;
  }

  if (!boat) {
    return <div>Boat data unavailable</div>;
  }

  return (
    <div>
      <Marker
        latitude={boat.latitude}
        longitude={boat.longitude}
        rotation={boat?.heading - viewport?.bearing}
        onClick={togglePopup}
        ref={markerRef}
      >
        <img
          src={"/havocboat.png"}
          style={{ width: 25, height: 50 }}
          alt="Boat"
        />
      </Marker>
      {showPopup && (
        <Popup
          latitude={boat.latitude}
          longitude={boat.longitude}
          closeButton={true}
          closeOnClick={false}
          onClose={() => setShowPopup(false)}
          anchor="bottom"
        >
          <div>{`${boatID.split("/")[5]} | Hdg: ${Math.round(
            boat.heading
          )} | Speed: ${Math.round(boat.speed)} knots`}</div>
          <div>{checkSystemHealth(boat.os_health)}</div>
          <div>
            Play: {boat.playID} | Task: {boat.taskID}
          </div>
          <div>Sector: {boatID.split("/")[1]}</div>
          <div>Team: {boatID.split("/")[3]}</div>
        </Popup>
      )}
      <PlannedPath waypoints={boat.route} color="green" />
    </div>
  );
}
