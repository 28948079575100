import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  Box,
  Stepper,
  Step,
  StepLabel,
  StepContent,
  Button,
  Typography,
  Stack,
} from "@mui/material";
import { getFirestore, doc, updateDoc } from "firebase/firestore";
import { app } from "../api/firebase";
import InterceptorTargetControl from "./InterceptorTargetControl";
import {
  convertCoordsToObjArray,
  findSectorContainingPoints,
  simplifyCoordinates,
} from "../utilities/features";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import {
  createBoundingBoxWithOnePoint,
  generateString,
} from "../api/utilities";
import { setMapMode } from "../data/slices/mapSlice";
import PlanBoatList from "./PlanBoatList";
import {
  clearSearchAreaPts,
  fetchPlan,
  getBoatsInMissionArea,
  sendSensorTrack,
} from "../data/slices/planSlice";

import { sendPlay, getHeatmap } from "../api/plan";
import { sendMissionGeofence } from "../api/plan";
const darkTheme = createTheme({
  palette: {
    mode: "dark",
  },
});

export default function InterceptorControl() {
  const db = getFirestore(app);
  const dispatch = useDispatch();
  const [activeStep, setActiveStep] = useState(0);
  const { currentPlan, boatsInPlan, geofence, searchAreaPts, testTrackPt } =
    useSelector((state) => state.plan);
  const assignedSector = useSelector((state) => state.sectors.assignedSector);
  const assignedTeam = useSelector((state) => state.sectors.assignedTeam);
  const geofencePts = useSelector((state) => state.plan.geofence);
  const updateGeofencePts = async (geofencePts) => {
    const planRef = doc(db, "plans", currentPlan.id);
    const converted = convertCoordsToObjArray(geofencePts);
    await updateDoc(planRef, { geofencePts: converted });
  };

  const updateSearchArea = async () => {
    console.log("search area pts ", searchAreaPts);
    const planRef = doc(db, "plans", currentPlan.id);
    const converted = convertCoordsToObjArray(searchAreaPts);
    await updateDoc(planRef, { searchArea: converted });
  };

  const updateTestTarget = async () => {
    const planRef = doc(db, "plans", currentPlan.id);

    await updateDoc(planRef, {
      testTrackPt: {
        id: generateString(10),
        coordinates: testTrackPt,
      },
    });

    dispatch(fetchPlan(currentPlan.id));
  };

  const onSetGeofence = async () => {
    let coords = [];
    let boats = [];
    for (let c of boatsInPlan) {
      let boatSpl = c.split("/");
      const boatID = `${boatSpl[0]}/${boatSpl[1]}/${boatSpl[2]}/${boatSpl[3]}`;
      boats.push(boatID);
    }
    for (let c of geofencePts) {
      coords.push({ x: c[0], y: c[1], z: 0 });
    }
    coords.push(coords[0]);
    console.log("coords ", coords);
    const geofence = {
      header: {
        stamp: {
          sec: Math.floor(Date.now() / 1000), // seconds since epoch
          nanosec: (Date.now() % 1000) * 1000000, // nanoseconds
        },
        frame_id: "geofence",
      },
      polygon: {
        points: coords,
      },
    };
    const payload1 = {
      sector: assignedSector.name,
      team: assignedTeam,
      geofence: geofence,
    };
    sendMissionGeofence(payload1);
  };

  const onSelectMissionArea = () => {
    dispatch(setMapMode("interceptorSelectMissionArea"));
  };

  const onCreateNewSearchArea = async () => {
    dispatch(clearSearchAreaPts());
    dispatch(setMapMode("addSearchAreaPoint"));
  };

  const onCreateTestTrack = async () => {
    dispatch(setMapMode("addTestTrackPoint"));
  };

  const onSendSensorTrack = async () => {
    console.log("on send sensor track ", {
      id: currentPlan.testTrackPt.id,
      lat: currentPlan.testTrackPt.coordinates[1],
      lng: currentPlan.testTrackPt.coordinates[0],
      sector: assignedSector.name,
      team: assignedTeam,
    });
    dispatch(
      sendSensorTrack({
        id: currentPlan.testTrackPt.id,
        lat: currentPlan.testTrackPt.coordinates[1],
        lng: currentPlan.testTrackPt.coordinates[0],
        sector: assignedSector.name,
        team: assignedTeam,
      })
    );
  };

  const onSendPlay4 = async () => {
    console.log("searchArea: " + searchAreaPts);
    let poly = [];
    for (let pt of searchAreaPts) {
      poly.push({ x: pt[0], y: pt[1], z: 0 });
    }
    poly = [{ points: poly }];
    const task1 = {
      task_id: 1,
      geo_pose: [
        {
          position: {
            longitude: 0.0,
            latitude: 0.0,
            altitude: 0.0,
          },
          orientation: {
            x: 0.0,
            y: 0.0,
            z: 0.0,
            w: 1.0,
          },
        },
      ],
      area: poly,
      auth_proceed: true,
      auth_continue: true,
      reference_track_uuid: currentPlan?.testTrackPt?.id
        ? currentPlan?.testTrackPt?.id
        : "",
      configuration: 0,
      d0_m: 0.0,
      d1_m: 0.0,
      a_rad: 0.0,
      b_rad: 0.0,
      target_speed_mps: 3.0,
      respect_avoids: true,
      min_force: 1,
      goal_reach_tolerance_m: 10.0,
    };
    const play = {
      header: {
        stamp: {
          sec: Math.floor(Date.now() / 1000), // seconds since epoch
          nanosec: (Date.now() % 1000) * 1000000, // nanoseconds
        },
        frame_id: "play4",
      },
      playbook_version: 1,
      play_id: 4,
      task_parameters: [task1],
    };
    let data = {
      team: assignedTeam,
      sector: assignedSector.name,
      play: play,
    };
    console.log("data ", data);
    sendPlay(data);
  };

  const getHeatmapLocal = async () => {
    let data = {
      team: assignedTeam,
      sector: assignedSector.name,
    };

    let res = await getHeatmap(data);

    console.log("heatmap ", res);
  };
  useEffect(() => {
    if (geofence.length > 0) {
      updateGeofencePts(geofence);
      dispatch(getBoatsInMissionArea(geofence));
    }
  }, [geofence]);
  return (
    <ThemeProvider theme={darkTheme}>
      <Box sx={{ maxWidth: 400, maxHeight: 800, overflowY: "auto" }}>
        <Stack spacing={2}>
          <Button variant="contained" onClick={onSelectMissionArea}>
            Select Mission Area
          </Button>
          <Button variant="contained" onClick={onSetGeofence}>
            Send Geofence
          </Button>

          <Button variant="contained" onClick={onCreateNewSearchArea}>
            Create New Search Area
          </Button>
          <Button variant="contained" onClick={updateSearchArea}>
            Save Search Area
          </Button>
          <Button variant="contained" onClick={onCreateTestTrack}>
            Create Test Track
          </Button>
          <Button variant="contained" onClick={updateTestTarget}>
            Save Test Track
          </Button>
          <Button variant="contained" onClick={onSendSensorTrack}>
            Send Sensor Track
          </Button>
          <Button variant="contained" onClick={onSendPlay4}>
            Send Intercept Command
          </Button>
          <Button variant="contained" onClick={getHeatmapLocal}>
            Test Get Heatmap
          </Button>
          <Typography style={{ color: "white" }}>
            Boats Under Control
          </Typography>
          <PlanBoatList boats={boatsInPlan} />
        </Stack>
      </Box>
    </ThemeProvider>
  );
}
