import React, { useState } from "react";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { Box, Button, Stack } from "@mui/material";
import { setMapMode } from "../data/slices/mapSlice";
import { useDispatch, useSelector } from "react-redux";
import { sendPlay, sendRTL, sendDisarm } from "../api/plan";
import { sendMissionGeofence } from "../api/plan";
import SpeedSlider from "./SpeedSlider";
const darkTheme = createTheme({
  palette: {
    mode: "dark",
  },
});

export function Play1() {
  const dispatch = useDispatch();
  const [speed, setSpeed] = useState(1.5);
  const { directPoint, boatsInPlan } = useSelector((state) => state.plan);
  const assignedSector = useSelector((state) => state.sectors.assignedSector);
  const assignedTeam = useSelector((state) => state.sectors.assignedTeam);
  const activeAsset = useSelector((state) => state.map.activeAsset);
  const geofencePts = useSelector((state) => state.plan.geofence);
  const setDirectPoint = () => {
    dispatch(setMapMode("setDirectPoint"));
  };

  const onSetGeofence = async () => {
    let coords = [];
    let boats = [];
    for (let c of boatsInPlan) {
      let boatSpl = c.split("/");
      const boatID = `${boatSpl[0]}/${boatSpl[1]}/${boatSpl[2]}/${boatSpl[3]}`;
      boats.push(boatID);
    }
    for (let c of geofencePts) {
      coords.push({ x: c[0], y: c[1], z: 0 });
    }
    coords.push(coords[0]);
    console.log("coords ", coords);
    const geofence = {
      header: {
        stamp: {
          sec: Math.floor(Date.now() / 1000), // seconds since epoch
          nanosec: (Date.now() % 1000) * 1000000, // nanoseconds
        },
        frame_id: "geofence",
      },
      polygon: {
        points: coords,
      },
    };
    const payload1 = {
      sector: assignedSector.name,
      team: assignedTeam,
      geofence: geofence,
    };
    sendMissionGeofence(payload1);
  };
  const sendLocalPlay = () => {
    let target_speed = speed * 0.514444;
    let task = {
      task_id: 1,
      geo_pose: [
        {
          position: {
            longitude: directPoint.lng,
            latitude: directPoint.lat,
            altitude: 0.0,
          },
          orientation: {
            x: 0.0,
            y: 0.0,
            z: 0.0,
            w: 1.0,
          },
        },
      ],
      area: [],
      auth_proceed: true,
      auth_continue: true,
      reference_track_uuid: "",
      configuration: 0,
      d0_m: 0.0,
      d1_m: 0.0,
      a_rad: 0.0,
      b_rad: 0.0,
      target_speed_mps: target_speed,
      respect_avoids: true,
      min_force: 1,
      goal_reach_tolerance_m: 10.0,
    };
    let play = {
      header: {
        stamp: {
          sec: Math.floor(Date.now() / 1000), // seconds since epoch
          nanosec: (Date.now() % 1000) * 1000000, // nanoseconds
        },
        frame_id: "avoidzone",
      },
      playbook_version: 1,
      play_id: 1,
      task_parameters: [task],
    };
    let data = {
      team: assignedTeam,
      sector: assignedSector.name,
      play: play,
    };

    console.log("data ", data);
    sendPlay(data);
  };
  return (
    <ThemeProvider theme={darkTheme}>
      <Box sx={{ maxWidth: 600, maxHeight: 800 }}>
        <Stack spacing={2}>
          <Button variant="contained" onClick={onSetGeofence}>
            Send Geofence
          </Button>
          <Button variant="contained" onClick={setDirectPoint}>
            Set Direct Point
          </Button>
          <SpeedSlider speed={speed} setSpeed={setSpeed} />
          <Button variant="contained" onClick={sendLocalPlay}>
            Direct to Point
          </Button>
        </Stack>
      </Box>
    </ThemeProvider>
  );
}
