import React, { useState, useEffect } from "react";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { useSelector, useDispatch } from "react-redux";
import Divider from "@mui/material/Divider";

import {
  Box,
  Stepper,
  Step,
  StepLabel,
  StepContent,
  Button,
  Typography,
  Stack,
} from "@mui/material";
import { getFirestore, doc, updateDoc } from "firebase/firestore";
import { app } from "../api/firebase";
import {
  convertCoordsToObjArray,
  findSectorContainingPoints,
  simplifyCoordinates,
} from "../utilities/features";
import {
  setMissionGeofence,
  addMissionAvoidzones,
  addMissionGeofence,
} from "../data/slices/featureSlice";
import {
  addGeofencePoint,
  getBoatsInMissionArea,
  setGeofencePoints,
  setActiveStep,
  sendGoCommand,
} from "../data/slices/planSlice";
import { setMapMode } from "../data/slices/mapSlice";
import {
  sendMissionGeofence,
  sendMissionAvoidzones,
  sendGotoPt,
  sendPlay,
  sendTask,
} from "../api/plan";
import {
  createBoundingBox,
  findMidpoint,
  generateString,
} from "../api/utilities";
import PlanBoatList from "./PlanBoatList";
import MissionSelect from "./MissionSelect";
import GeofenceControl from "./GeofenceControl";
import AvoidZonesControl from "./AvoidZonesControl";
import TextField from "@mui/material/TextField";
import {
  getSectorsFromDb,
  setAssignedSector,
} from "../data/slices/sectorsSlice";
import { debounce } from "lodash";

const steps = [
  {
    label: "Pick Up and Drop Off Points",
    description: "Select pick up and drop off points below.",
  },
  {
    label: "Modify operational sector.",
    description: "An operational sector has been created for you.",
  },
  {
    label: "Manage Havoc Boats",
    description: "Add or remove available HavocBoats to mission.",
  },
  {
    label: "Create keep out zones",
    description:
      "Create areas that Havoc Boats should stay out of. Use the polygon tool.",
  },
  {
    label: "Review Mission and Execute",
    description: "Review the mission. Click Execute below to start mission.",
  },
];

const darkTheme = createTheme({
  palette: {
    mode: "dark",
  },
});

export default function ContestedLogisticsStepper() {
  const db = getFirestore(app);
  const dispatch = useDispatch();

  const sectors = useSelector((state) => state.sectors.sectors);
  const { activeFeature, missionGeofence } = useSelector(
    (state) => state.feature
  );

  const {
    currentPlan,
    boatsInPlan,
    pickup,
    dropoff,
    avoidZones,
    activeStep,
    dropoffTerminalArea,
    pickupTerminalArea,
  } = useSelector((state) => state.plan);
  const geofencePts = useSelector((state) => state.plan.geofence);
  const assignedSector = useSelector((state) => state.sectors.assignedSector);
  const assignedTeam = useSelector((state) => state.sectors.assignedTeam);
  const activeAsset = useSelector((state) => state.map.activeAsset);
  const activeBoat = useSelector((state) => state.map.activeBoat);

  useEffect(() => {
    dispatch(getSectorsFromDb());
  }, []);

  const updateFirestoreAndState = async (feature, sliceAction) => {
    const copy = simplifyCoordinates(feature);
    const planRef = doc(db, "plans", currentPlan.id);
    await updateDoc(planRef, { missionGeofence: copy });
    dispatch(sliceAction({ id: currentPlan.id, geofence: feature }));
  };

  const updateGeofencePts = async (geofencePts) => {
    const planRef = doc(db, "plans", currentPlan.id);
    const converted = convertCoordsToObjArray(geofencePts);
    await updateDoc(planRef, { geofencePts: converted });
  };

  const updateAvoidzones = async () => {
    const planRef = doc(db, "plans", currentPlan.id);
    let zones = [];
    for (let i in avoidZones) {
      let zone = avoidZones[i];
      let coords = convertCoordsToObjArray(zone.coords);
      zones.push({ id: zone.id, coords: coords });
    }
    await updateDoc(planRef, { avoidZones: zones });
  };

  const onSetGeofence = async () => {
    let coords = [];
    let boats = [];
    for (let c of boatsInPlan) {
      let boatSpl = c.split("/");
      const boatID = `${boatSpl[0]}/${boatSpl[1]}/${boatSpl[2]}/${boatSpl[3]}`;
      boats.push(boatID);
    }
    for (let c of geofencePts) {
      coords.push({ x: c[0], y: c[1], z: 0 });
    }
    coords.push(coords[0]);
    const geofence = {
      header: {
        stamp: {
          sec: Math.floor(Date.now() / 1000), // seconds since epoch
          nanosec: (Date.now() % 1000) * 1000000, // nanoseconds
        },
        frame_id: "geofence",
      },
      polygon: {
        points: coords,
      },
    };
    const payload1 = {
      sector: assignedSector.name,
      team: assignedTeam,
      geofence: geofence,
    };
    sendMissionGeofence(payload1);
  };

  const handleNext = async () => {
    switch (activeStep) {
      case 0:
        console.log(pickup, dropoff);
        const geofence = createBoundingBox(pickup, dropoff, boatsInPlan, 2);
        dispatch(setGeofencePoints(geofence));

        const sector = findSectorContainingPoints(sectors, pickup, dropoff);

        console.log("sector ", sector);

        break;
      case 1:
        // if (activeFeature) {

        //   await updateFirestoreAndState(activeFeature, addMissionGeofence);
        //   dispatch(
        //     getBoatsInMissionArea(activeFeature.geometry.coordinates[0])
        //   );
        // }
        if (geofencePts.length > 0) {
          updateGeofencePts(geofencePts);
        }
        dispatch(getBoatsInMissionArea(geofencePts));

        break;

      case 3:
        // dispatch(
        //   addMissionAvoidzones({
        //     id: currentPlan.id,
        //     avoidZone: activeFeature,
        //   })
        // );

        updateAvoidzones();

        break;
      case 4:
        onSetGeofence();
        sendAvoidZone();
        sendLocalPlay2();
        break;
      default:
        break;
    }

    if (activeStep < steps.length - 1) {
      dispatch(setActiveStep(activeStep + 1));
    }
  };
  const sendLocalPlay2 = () => {
    console.log(dropoffTerminalArea, pickupTerminalArea);

    const midPointTask3 = findMidpoint(
      pickupTerminalArea.stagingPoint,
      pickupTerminalArea.terminalPoint
    );
    const midPointTask5 = findMidpoint(
      pickupTerminalArea.terminalPoint,
      pickupTerminalArea.egressPoint
    );
    const midPointTask8 = findMidpoint(
      dropoffTerminalArea.stagingPoint,
      dropoffTerminalArea.terminalPoint
    );
    const midPointTask10 = findMidpoint(
      dropoffTerminalArea.terminalPoint,
      dropoffTerminalArea.egressPoint
    );

    console.log(midPointTask3, midPointTask5, midPointTask8, midPointTask10);
    let speed = 2.57;
    const task1 = {
      task_id: 1,
      geo_pose: [
        {
          position: {
            longitude: pickupTerminalArea.stagingPoint[0],
            latitude: pickupTerminalArea.stagingPoint[1],
            altitude: 0.0,
          },
          orientation: {
            x: 0.0,
            y: 0.0,
            z: 0.0,
            w: 1.0,
          },
        },
      ],
      area: [],
      auth_proceed: true,
      auth_continue: true,
      reference_track_uuid: "",
      configuration: 0,
      d0_m: 0.0,
      d1_m: 0.0,
      a_rad: 0.0,
      b_rad: 0.0,
      target_speed_mps: speed,
      respect_avoids: true,
      min_force: 1,
      goal_reach_tolerance_m: 100.0,
    };
    const task2 = {
      task_id: 2,
      geo_pose: [
        {
          position: {
            longitude: pickupTerminalArea.stagingPoint[0],
            latitude: pickupTerminalArea.stagingPoint[1],
            altitude: 0.0,
          },
          orientation: {
            x: 0.0,
            y: 0.0,
            z: 0.0,
            w: 1.0,
          },
        },
      ],
      area: [],
      auth_proceed: true,
      auth_continue: true,
      reference_track_uuid: "",
      configuration: 0,
      d0_m: 0.0,
      d1_m: 0.0,
      a_rad: 0.0,
      b_rad: 0.0,
      target_speed_mps: speed,
      respect_avoids: true,
      min_force: 1,
      goal_reach_tolerance_m: 100.0,
    };
    const task3 = {
      task_id: 3,
      geo_pose: [
        {
          position: {
            longitude: midPointTask3[0],
            latitude: midPointTask3[1],
            altitude: 0.0,
          },
          orientation: {
            x: 0.0,
            y: 0.0,
            z: 0.0,
            w: 1.0,
          },
        },
        {
          position: {
            longitude: pickupTerminalArea.terminalPoint[0],
            latitude: pickupTerminalArea.terminalPoint[1],
            altitude: 0.0,
          },
          orientation: {
            x: 0.0,
            y: 0.0,
            z: 0.0,
            w: 1.0,
          },
        },
      ],
      area: [],
      auth_proceed: true,
      auth_continue: true,
      reference_track_uuid: "",
      configuration: 0,
      d0_m: 0.0,
      d1_m: 0.0,
      a_rad: 0.0,
      b_rad: 0.0,
      target_speed_mps: speed,
      respect_avoids: true,
      min_force: 1,
      goal_reach_tolerance_m: 100.0,
    };

    const task4 = {
      task_id: 4,
      geo_pose: [
        {
          position: {
            longitude: midPointTask5[0],
            latitude: midPointTask5[1],
            altitude: 0.0,
          },
          orientation: {
            x: 0.0,
            y: 0.0,
            z: 0.0,
            w: 1.0,
          },
        },
        {
          position: {
            longitude: pickupTerminalArea.egressPoint[0],
            latitude: pickupTerminalArea.egressPoint[1],
            altitude: 0.0,
          },
          orientation: {
            x: 0.0,
            y: 0.0,
            z: 0.0,
            w: 1.0,
          },
        },
      ],
      area: [],
      auth_proceed: true,
      auth_continue: true,
      reference_track_uuid: "",
      configuration: 0,
      d0_m: 0.0,
      d1_m: 0.0,
      a_rad: 0.0,
      b_rad: 0.0,
      target_speed_mps: speed,
      respect_avoids: true,
      min_force: 1,
      goal_reach_tolerance_m: 100.0,
    };
    const task5 = {
      task_id: 5,
      geo_pose: [
        {
          position: {
            longitude: dropoffTerminalArea.stagingPoint[0],
            latitude: dropoffTerminalArea.stagingPoint[1],
            altitude: 0.0,
          },
          orientation: {
            x: 0.0,
            y: 0.0,
            z: 0.0,
            w: 1.0,
          },
        },
      ],
      area: [],
      auth_proceed: true,
      auth_continue: true,
      reference_track_uuid: "",
      configuration: 0,
      d0_m: 0.0,
      d1_m: 0.0,
      a_rad: 0.0,
      b_rad: 0.0,
      target_speed_mps: speed,
      respect_avoids: true,
      min_force: 1,
      goal_reach_tolerance_m: 100.0,
    };
    const task6 = {
      task_id: 6,
      geo_pose: [
        {
          position: {
            longitude: dropoffTerminalArea.stagingPoint[0],
            latitude: dropoffTerminalArea.stagingPoint[1],
            altitude: 0.0,
          },
          orientation: {
            x: 0.0,
            y: 0.0,
            z: 0.0,
            w: 1.0,
          },
        },
      ],
      area: [],
      auth_proceed: true,
      auth_continue: true,
      reference_track_uuid: "",
      configuration: 0,
      d0_m: 0.0,
      d1_m: 0.0,
      a_rad: 0.0,
      b_rad: 0.0,
      target_speed_mps: speed,
      respect_avoids: true,
      min_force: 1,
      goal_reach_tolerance_m: 100.0,
    };
    const task7 = {
      task_id: 7,
      geo_pose: [
        {
          position: {
            longitude: midPointTask8[0],
            latitude: midPointTask8[1],
            altitude: 0.0,
          },
          orientation: {
            x: 0.0,
            y: 0.0,
            z: 0.0,
            w: 1.0,
          },
        },
        {
          position: {
            longitude: dropoffTerminalArea.terminalPoint[0],
            latitude: dropoffTerminalArea.terminalPoint[1],
            altitude: 0.0,
          },
          orientation: {
            x: 0.0,
            y: 0.0,
            z: 0.0,
            w: 1.0,
          },
        },
      ],
      area: [],
      auth_proceed: true,
      auth_continue: true,
      reference_track_uuid: "",
      configuration: 0,
      d0_m: 0.0,
      d1_m: 0.0,
      a_rad: 0.0,
      b_rad: 0.0,
      target_speed_mps: speed,
      respect_avoids: true,
      min_force: 1,
      goal_reach_tolerance_m: 100.0,
    };

    const task8 = {
      task_id: 8,
      geo_pose: [
        {
          position: {
            longitude: midPointTask10[0],
            latitude: midPointTask10[1],
            altitude: 0.0,
          },
          orientation: {
            x: 0.0,
            y: 0.0,
            z: 0.0,
            w: 1.0,
          },
        },
        {
          position: {
            longitude: dropoffTerminalArea.egressPoint[0],
            latitude: dropoffTerminalArea.egressPoint[1],
            altitude: 0.0,
          },
          orientation: {
            x: 0.0,
            y: 0.0,
            z: 0.0,
            w: 1.0,
          },
        },
      ],
      area: [],
      auth_proceed: true,
      auth_continue: true,
      reference_track_uuid: "",
      configuration: 0,
      configuration: 0,
      d0_m: 0.0,
      d1_m: 0.0,
      a_rad: 0.0,
      b_rad: 0.0,
      target_speed_mps: speed,
      respect_avoids: true,
      min_force: 1,
      goal_reach_tolerance_m: 100.0,
    };
    const task9 = {
      task_id: 9,
      geo_pose: [
        {
          position: {
            longitude: pickupTerminalArea.stagingPoint[0],
            latitude: pickupTerminalArea.stagingPoint[1],
            altitude: 0.0,
          },
          orientation: {
            x: 0.0,
            y: 0.0,
            z: 0.0,
            w: 1.0,
          },
        },
      ],
      area: [],
      auth_proceed: true,
      auth_continue: true,
      reference_track_uuid: "",
      configuration: 0,
      d0_m: 0.0,
      d1_m: 0.0,
      a_rad: 0.0,
      b_rad: 0.0,
      target_speed_mps: speed,
      respect_avoids: true,
      min_force: 1,
      goal_reach_tolerance_m: 100.0,
    };
    const play = {
      header: {
        stamp: {
          sec: Math.floor(Date.now() / 1000), // seconds since epoch
          nanosec: (Date.now() % 1000) * 1000000, // nanoseconds
        },
        frame_id: "avoidzone",
      },
      playbook_version: 1,
      play_id: 2,
      task_parameters: [
        task1,
        task2,
        task3,
        task4,
        task5,
        task6,
        task7,
        task8,
        task9,
      ],
    };
    let data = {
      team: assignedTeam,
      sector: assignedSector.name,
      play: play,
    };

    console.log("data ", data);
    sendPlay(data);
  };

  const sendAvoidZone = () => {
    let avoidzones = [];
    for (let a of avoidZones) {
      let points = [];
      for (let p of a.coords) {
        points.push({
          x: p[0],
          y: p[1],
          z: 0,
        });
      }
      const avoidzone = {
        header: {
          stamp: {
            sec: Math.floor(Date.now() / 1000), // seconds since epoch
            nanosec: (Date.now() % 1000) * 1000000, // nanoseconds
          },
          frame_id: "avoidzone",
        },
        polygon: {
          points: points,
        },
      };
      avoidzones.push(avoidzone);
    }

    const payload = {
      sector: assignedSector.name,
      team: assignedTeam,
      avoidzones: avoidzones,

      // avoidzones: avoidZones.map((z) =>
      //   z.coords.map((c) => `${c[1]}_${c[0]}`).join("|")
      // ),
    };
    sendMissionAvoidzones(payload);
  };

  const handleBack = () => dispatch(setActiveStep(activeStep - 1));
  const handleReset = () => dispatch(setActiveStep(0));

  const renderStepContent = (index) => (
    <Box>
      <Typography style={{ color: "white" }}>
        {steps[index].description}
      </Typography>
      {/* {index === 0 && <MissionSelect />} */}
      {index === 1 && <GeofenceControl />}
      {index === 2 && <PlanBoatList boats={boatsInPlan} />}
      {index === 3 && <AvoidZonesControl sendAvoidZone={sendAvoidZone} />}
      {index === 0 && (
        <Stack direction="column" spacing={2}>
          <TextField
            id="outlined-basic"
            label="Number of Boats Needed"
            variant="outlined"
          />
          <Stack direction="row" spacing={2}>
            <Button
              variant="contained"
              onClick={() => dispatch(setMapMode("pickup"))}
            >
              Set Pick-Up Point
            </Button>
            <Button
              variant="contained"
              onClick={() => dispatch(setMapMode("dropoff"))}
            >
              Set Drop-Off Point
            </Button>
          </Stack>
        </Stack>
      )}
      <Stack spacing={2} sx={{ marginTop: 5 }}>
        <Button variant="contained" onClick={handleNext} color="success">
          {index === steps.length - 1 ? "Execute" : "Continue"}
        </Button>
        {index == 5 && (
          <Button variant="contained" onClick={async () => {}}>
            Send New Pickup Point
          </Button>
        )}
        <Stack
          alignItems={"center"}
          justifyContent={"center"}
          spacing={2}
          sx={{ marginTop: 5 }}
          direction="row"
        >
          <Button
            disabled={index === 0}
            onClick={handleBack}
            style={{ color: "white" }}
          >
            Back
          </Button>
          <Button
            disabled={index === 0}
            onClick={handleReset}
            style={{ color: "white" }}
          >
            Reset
          </Button>
        </Stack>
      </Stack>
    </Box>
  );

  const onClick = (e) => {
    dispatch(
      sendGoCommand({
        boat_id: activeAsset?.split("/")[5],
        action: "GO",
      })
    );
  };

  return (
    <ThemeProvider theme={darkTheme}>
      <Box sx={{ maxWidth: 400, maxHeight: 800, overflowY: "auto" }}>
        <Stepper activeStep={activeStep} orientation="vertical">
          {steps.map((step, index) => (
            <Step key={step.label} style={{ color: "white" }}>
              <StepLabel
                sx={{
                  ".MuiStepLabel-label": {
                    color: "white !important",
                  },
                }}
              >
                {step.label}
              </StepLabel>

              <StepContent>{renderStepContent(index)}</StepContent>
            </Step>
          ))}
        </Stepper>

        <Divider style={{ width: "90%", color: "white" }} />
        {activeAsset && (
          <Stack spacing={2} sx={{ marginTop: 5 }} alignItems={"center"}>
            <Typography style={{ color: "white" }}>
              Active Asset: {activeAsset?.split("/")[5]}
            </Typography>

            <Button
              variant="contained"
              style={{ width: 300, height: 100 }}
              disabled={activeAsset === null || activeBoat.taskID === 1}
              onClick={debounce(onClick, 1000)}
            >
              GO
            </Button>
          </Stack>
        )}
      </Box>
    </ThemeProvider>
  );
}
