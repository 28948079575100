/**
 * Main entry point for the React application.
 *
 * This file sets up the React application by importing necessary modules and components,
 * creating a React Router for handling navigation, and wrapping the entire application
 * in a Redux Provider for state management.
 *
 * Components Imported:
 * - App: The main application component that acts as the root of the project.
 * - Mission, MissionPlan, SITLDashboard, ProtectedRoute, SignIn, TerminalPlan:
 *   Components used for different views within the application, some of which are
 *   protected and require authentication.
 *
 * Libraries and Utilities Used:
 * - React and ReactDOM: For building and rendering the UI components.
 * - react-router-dom: For client-side routing within the application.
 * - react-redux: For managing application state globally.
 * - reportWebVitals: Utility for measuring and analyzing the performance of the app.
 *
 * Routing:
 * Setup includes a BrowserRouter with defined routes for the application, handling URLs
 * and their corresponding views. Protected routes are wrapped in a ProtectedRoute component
 * to ensure authentication.
 *
 * State Management:
 * Utilizes Redux for global state management, with the store provided to the entire application
 * through the Redux Provider.
 *
 * Performance Monitoring:
 * Includes an optional setup for monitoring and reporting web vitals, which can help in analyzing
 * the performance of the application.
 *
 * Usage:
 * - `npm start` to run the application in development mode.
 * - Deploy this build in a server environment to serve the application in production.
 *
 * For more information on React and creating applications with Create React App, visit:
 * https://reactjs.org/docs/create-a-new-react-app.html#create-react-app
 */

import React from "react";
import ReactDOM from "react-dom/client";
import { Provider } from "react-redux";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import App from "./components/App";
import { BoatWS } from "./components/BoatWS";
import { store } from "./data/store";
import "./index.css";
import reportWebVitals from "./reportWebVitals";

import MissionPlan from "./views/MissionPlan";
import ProtectedRoute from "./views/ProtectedRoute";
import SignIn from "./views/SignIn";
import SITLDashboard from "./views/SITLDashboard";
import TerminalPlan from "./views/TerminalPlan";
import MissionPlanNew from "./views/MissionPlanNew";
import { CookiesProvider } from "react-cookie";

const router = createBrowserRouter([
  // {
  //   path: "/mission/:missionID",
  //   element: <Mission />,
  // },
  {
    path: "/",
    element: (
      <ProtectedRoute>
        <App />
      </ProtectedRoute>
    ),
  },
  {
    path: "/plan/:planID",
    element: (
      <ProtectedRoute>
        <MissionPlan />
      </ProtectedRoute>
    ),
  },
  {
    path: "/save/:planID",
    element: (
      <ProtectedRoute>
        <MissionPlanNew />
      </ProtectedRoute>
    ),
  },
  {
    path: "/sitl_dash",
    element: (
      <ProtectedRoute>
        <SITLDashboard />
      </ProtectedRoute>
    ),
  },
  {
    path: "/login",
    element: <SignIn />,
  },
  {
    path: "/terminal",
    element: <TerminalPlan />,
  },
  {
    path: "/ws_test",
    element: <BoatWS />,
  },
]);
const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <CookiesProvider>
    <Provider store={store}>
      <RouterProvider router={router} />
    </Provider>
  </CookiesProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
