import React from "react";
import { Source, Layer } from "react-map-gl";

const AvoidZones = ({ avoidZones }) => {
  return (
    <>
      {avoidZones?.map((avoid, index) => {
        const avoidZoneData = {
          type: "Feature",
          properties: {},
          geometry: {
            type: "Polygon",
            coordinates: [avoid.coords],
          },
        };

        return (
          <Source
            key={index}
            id={`polygon-${index}`}
            type="geojson"
            data={avoidZoneData}
          >
            <Layer
              id={`${avoid.id}`}
              type="fill"
              paint={{
                "fill-color": "red", // Customize the polygon color
                "fill-opacity": 0.2, // Customize the polygon opacity
              }}
            />
          </Source>
        );
      })}
    </>
  );
};

export default AvoidZones;
