import { createSliceWithThunks } from "../../utilities/createSliceWithThunks";
import { app } from "../../api/firebase";
import { getFirestore, doc, getDoc, updateDoc } from "firebase/firestore";
import axios from "axios";
import { url } from "../../api/url";
import { generateString } from "../../api/utilities";
import { convertObjArrayToCoords } from "../../utilities/features";
const db = getFirestore(app);

// export const fetchPlan = createAsyncThunk("plan/fetchPlan", async (planID) => {
//   const docRef = doc(db, "plans", planID);
//   const docSnap = await getDoc(docRef);
//   if (docSnap.exists()) {
//     return { id: docSnap.id, ...docSnap.data() };
//   } else {
//     // doc.data() will be undefined in this case
//     console.log("No such document!");
//   }
// });
const planSlice = createSliceWithThunks({
  name: "plan",
  initialState: {
    currentPlanID: "",
    currentPlan: null,
    plans: [],
    boatsInPlan: [],
    pickup: null,
    dropoff: null,
    waypoints: [],
    geofence: [],
    addZonePts: [],
    avoidZones: [],
    missionType: "contested_logistics",
    selectedTarget: null,
    tdoaLines: null,
    tdoaPoint: null,
    showTargets: true,
    showTDOALines: false,
    plannerID: null,
    activeAZID: null,
    activeStep: 0,
    dropoffTerminalArea: null,
    pickupTerminalArea: null,
    selectedBoatID: null,
    directPoint: null,
    searchAreaPts: [],
    testTrackPt: [],
  },
  reducers: (create) => ({
    setCurrentPlanID: create.reducer((state, action) => {
      state.currentPlanID = action.payload;
    }),
    setPlans: create.reducer((state, action) => {
      state.plans = action.payload;
    }),
    setCurrentPlan: create.reducer((state, action) => {
      state.currentPlan = action.payload;
    }),
    fetchPlan: create.asyncThunk(
      async (id, thankApi) => {
        const docRef = doc(db, "plans", id);
        const docSnap = await getDoc(docRef);
        if (docSnap.exists()) {
          return { id: docSnap.id, ...docSnap.data() };
        } else {
          // doc.data() will be undefined in this case
          console.log("No such document!");
        }
      },
      {
        fulfilled: (state, action) => {
          state.currentPlan = action.payload;
          if (action?.payload?.searchArea) {
            state.searchAreaPts = convertObjArrayToCoords(
              action?.payload?.searchArea
            );
          }
          if (action?.payload?.testTrackPt?.coordinates) {
            state.testTrackPt = action.payload.testTrackPt.coordinates;
          }
        },
      }
    ),

    getBoatsInMissionArea: create.asyncThunk(
      async (poly) => {
        console.log("poly ", poly);
        console.log("calling boats in area");
        const response = await axios.post(
          url + "get_boats_in_area",
          // "http://localhost:8080/get_boats_in_area",
          poly
        );
        console.log("response ", response);
        return response.data;
      },
      {
        fulfilled: (state, action) => {
          state.boatsInPlan = action.payload;
        },
      }
    ),
    sendSensorTrack: create.asyncThunk(async (obj) => {
      const sensorMsg = {
        header: {
          stamp: {
            sec: Math.floor(Date.now() / 1000), // seconds since epoch
            nanosec: (Date.now() % 1000) * 1000000, // nanoseconds
          },
          frame_id: "avoidzone",
        },
        uuid: obj.id,
        stale: false,
        geo_pose_with_covariance: {
          pose: {
            position: {
              longitude: obj.lng,
              latitude: obj.lat,
              altitude: 0.0,
            },
            orientation: {
              x: 0.0,
              y: 0.0,
              z: 0.0,
              w: 1.0,
            },
          },
          covariance: [
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
            0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
          ],
        },
        twist: {
          linear: {
            x: 0.0,
            y: 0.0,
            z: 0.0,
          },
          angular: {
            x: 0.0,
            y: 0.0,
            z: 0.0,
          },
        },
        linear_velocity_valid: true,
        angular_velocity_valid: true,
        meta_is_neighbor: false,
        meta_ais_status: false,
        meta_ship_dim_to_bow_m: 0,
        meta_ship_dim_to_stern_m: 0,
        meta_ship_dim_to_port_m: 0,
        meta_ship_dim_to_stbd_m: 0,
      };
      // const response = await axios.post(url + "send_sensor_track", {
      //   sector: obj.sector,
      //   team: obj.team,
      //   sensor_track: sensorMsg,
      // });
      for (let i = 0; i < 10; i++) {
        try {
          const response = await axios.post(url + "send_sensor_track", {
            sector: obj.sector,
            team: obj.team,
            sensor_track: sensorMsg,
          });
          console.log(`Response ${i + 1}:`, response.data);
        } catch (error) {
          console.error(`Error ${i + 1}:`, error);
        }
        await new Promise((resolve) => setTimeout(resolve, 1000)); // Wait for 1 second
      }
      console.log({
        sector: obj.sector,
        team: obj.team,
        sensor_track: sensorMsg,
      });
    }, {}),

    addPickupLocation: create.asyncThunk(
      async (obj, thankapi) => {
        const docRef = doc(db, "plans", obj.id);
        updateDoc(docRef, {
          pickupLocation: obj,
        });
        return obj;
      },
      {
        fulfilled: (state, action) => {
          console.log("in fulfilled ");
          state.pickup = action.payload;
        },
      }
    ),
    setPickup: create.reducer((state, action) => {
      state.pickup = action.payload;
    }),
    setDirectPoint: create.reducer((state, action) => {
      state.directPoint = action.payload;
      console.log("setDirectPoint ", action.payload);
    }),
    removeBoatInPlan: create.reducer((state, action) => {
      // Assuming action.payload is the identifier of the boat to be removed
      const boatToRemove = action.payload;

      // Filter out the boat to be removed
      state.boatsInPlan = state.boatsInPlan.filter(
        (boat) => boat !== boatToRemove
      );
    }),
    addDropoffLocation: create.asyncThunk(
      async (obj, thankapi) => {
        const docRef = doc(db, "plans", obj.id);
        updateDoc(docRef, {
          dropoffLocation: obj,
        });
        return obj;
      },
      {
        fulfilled: (state, action) => {
          state.dropoff = action.payload;
        },
      }
    ),
    setDropoff: create.reducer((state, action) => {
      state.dropoff = action.payload;
    }),
    setDropoffTerminalArea: create.reducer((state, action) => {
      state.dropoffTerminalArea = action.payload;
    }),
    setPickupTerminalArea: create.reducer((state, action) => {
      state.pickupTerminalArea = action.payload;
    }),
    setWaypoints: create.reducer((state, action) => {
      console.log("setting waypoints ", action.payload.path);
      state.waypoints = action.payload.path;
    }),
    addGeofencePoint: create.reducer((state, action) => {
      state.geofence.push(action.payload);
    }),
    setGeofencePoints: create.reducer((state, action) => {
      state.geofence = action.payload;
    }),
    addZonePts: create.reducer((state, action) => {
      state.addZonePts.push(action.payload);
    }),
    addAvoidZone: create.reducer((state, action) => {
      state.avoidZones.push({
        id: generateString(10),
        coords: state.addZonePts,
      });
      state.addZonePts = [];
    }),
    addSearchAreaPts: create.reducer((state, action) => {
      state.searchAreaPts.push(action.payload);
    }),
    setTestTrackPt: create.reducer((state, action) => {
      state.testTrackPt = action.payload;
    }),
    clearSearchAreaPts: create.reducer((state, action) => {
      state.searchAreaPts = [];
    }),
    setAvoidZones: create.reducer((state, action) => {
      state.avoidZones = action.payload;
    }),
    setMissionType: create.reducer((state, action) => {
      state.missionType = action.payload;
    }),
    setSelectedTarget: create.reducer((state, action) => {
      state.selectedTarget = action.payload;
    }),
    setTdoaLines: create.reducer((state, action) => {
      state.tdoaLines = action.payload;
    }),
    setTdoaPoint: create.reducer((state, action) => {
      state.tdoaPoint = action.payload;
    }),
    setShowTargets: create.reducer((state, action) => {
      state.showTargets = action.payload;
    }),
    setPlannerID: create.reducer((state, action) => {
      if (state.plannerID === null) {
        state.plannerID = generateString(10);
      }
    }),
    setSelectedBoatID: create.reducer((state, action) => {
      state.selectedBoatID = action.payload;
    }),
    setPlan: create.reducer((state, action) => {
      state.currentPlan = action.payload;
    }),
    setShowTDOALines: create.reducer((state, action) => {
      state.showTDOALines = action.payload;
    }),
    setAddZonePts: create.reducer((state, action) => {
      state.addZonePts = action.payload;
    }),
    setActiveAvoidZoneID: create.reducer((state, action) => {
      state.activeAZID = action.payload;
    }),
    setActiveStep: create.reducer((state, action) => {
      state.activeStep = action.payload;
    }),
    updateAvoidZone: create.reducer((state, action) => {
      return {
        ...state,
        avoidZones: state.avoidZones.map((zone) => {
          if (zone.id === action.payload.id) {
            // Found the matching zone, return a new object with updated coords
            return {
              ...zone,
              coords: action.payload.coords,
            };
          }
          // Not the zone we're looking for, return the original zone
          return zone;
        }),
      };
    }),

    takeSimControl: create.asyncThunk(async (obj, thankapi) => {
      console.log("in take control ", obj);
      const docRef = doc(db, "plans", obj.planID);
      updateDoc(docRef, {
        controlID: obj.plannerID,
      });
      return obj;
    }, {}),
    sendGoCommand: create.asyncThunk(async (obj, thankapi) => {
      console.log("in send go command ", obj);
      await axios.post(url + "send_boat_action", obj);
      return obj;
    }, {}),
    deleteBoat: create.asyncThunk(async (obj, thankapi) => {
      console.log("in delete boat ", obj);
      await axios.post(url + "delete_boat", obj);
      return obj;
    }, {}),
  }),
});

export const {
  setCurrentPlan,
  setPlans,
  setCurrentPlanID,
  fetchPlan,
  getBoatsInMissionArea,
  addPickupLocation,
  setPickup,
  addDropoffLocation,
  setDropoff,
  setWaypoints,
  removeBoatInPlan,
  addGeofencePoint,
  setGeofencePoints,
  addZonePts,
  addAvoidZone,
  setMissionType,
  setSelectedTarget,
  setTdoaLines,
  setTdoaPoint,
  setShowTargets,
  setPlan,
  takeSimControl,
  setPlannerID,
  setShowTDOALines,
  setAvoidZones,
  setAddZonePts,
  setActiveAvoidZoneID,
  updateAvoidZone,
  setActiveStep,
  setPickupTerminalArea,
  setDropoffTerminalArea,
  setSelectedBoatID,
  sendGoCommand,
  setDirectPoint,
  deleteBoat,
  addSearchAreaPts,
  clearSearchAreaPts,
  setTestTrackPt,
  sendSensorTrack,
} = planSlice.actions;
export default planSlice.reducer;
