import * as React from "react";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import Box from "@mui/material/Box";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import { useDispatch, useSelector } from "react-redux";
import { setMapMode } from "../data/slices/mapSlice";
import { Typography } from "@mui/material";
import { useLocation, useParams } from "react-router-dom";
import { usePlanData } from "../hooks/usePlanData";
import {
  setShowTargets,
  setShowTDOALines,
  setTdoaLines,
  setTdoaPoint,
} from "../data/slices/planSlice";
import { useInterval } from "../api/useInterval";
import {
  doc,
  onSnapshot,
  getFirestore,
  updateDoc,
  getDoc,
} from "firebase/firestore";
import { app } from "../api/firebase";
import {
  sendInterceptorCommand,
  sendSensorTargetData,
  sendStalkCommand,
} from "../api/simTargets";
import {
  calculateCentroid,
  calculateDistance,
  generateHyperbolicLinePoints,
} from "../api/tdoa";
// Create a theme instance.
const darkTheme = createTheme({
  palette: {
    mode: "dark",
  },
});

export default function InterceptorTargetControl() {
  const dispatch = useDispatch();
  const db = getFirestore(app);
  const selectedTarget = useSelector((state) => state.plan.selectedTarget);
  const boatsInPlan = useSelector((state) => state.plan.boatsInPlan);
  const showTargets = useSelector((state) => state.plan.showTargets);
  const showTDOALines = useSelector((state) => state.plan.showTDOALines);
  const { planID } = useParams();

  const { plan } = usePlanData();
  const onCreateSimTarget = async () => {
    dispatch(setMapMode("create_sim_target"));
  };

  const onCreateSimBoat = async () => {
    dispatch(setMapMode("create_sim_boat"));
  };

  const handleMoveTrack = async (lat, lon) => {
    dispatch(setMapMode("move_sim_track"));
  };
  const handleSetSpeed = () => {
    const input = prompt("Enter speed (nautical miles per hour):", "");
    if (input !== null) {
      const newSpeed = parseFloat(input);
      if (isNaN(newSpeed) || newSpeed < 0 || newSpeed > 60) {
        // Assuming max speed is 60
        alert("Speed must be a number between 0 and 60.");
        return;
      }
      const docRef = doc(db, "sim_targets", selectedTarget);
      updateDoc(docRef, { speed: newSpeed });
      // Update speed state here
      console.log(`Speed set to: ${newSpeed}`); // Replace this with your state update logic
    }
  };

  const handleSetHeading = () => {
    const input = prompt("Enter heading (degrees):", "");
    if (input !== null) {
      const newHeading = parseFloat(input);
      if (isNaN(newHeading) || newHeading < 0 || newHeading >= 360) {
        alert("Heading must be a number between 0 and 359.");
        return;
      }
      const docRef = doc(db, "sim_targets", selectedTarget);
      updateDoc(docRef, { heading: newHeading });
      // Update heading state here
      console.log(`Heading set to: ${newHeading}`); // Replace this with your state update logic
    }
  };

  const handleSendTrack = async () => {
    const docRef = doc(db, "sim_targets", selectedTarget);
    const docSnap = await getDoc(docRef);

    if (docSnap.exists()) {
      const tgt = docSnap.data();
      sendSensorTargetData(selectedTarget, tgt);
    } else {
      // docSnap.data() will be undefined in this case
      console.log("No such document!");
    }
  };

  const handleIntercept = async () => {
    console.log("boats in plan ", boatsInPlan);
    const docRef = doc(db, "sim_targets", selectedTarget);
    const docSnap = await getDoc(docRef);

    if (docSnap.exists()) {
      const tgt = docSnap.data();

      sendInterceptorCommand(selectedTarget, tgt, boatsInPlan);
    } else {
      // docSnap.data() will be undefined in this case
      console.log("No such document!");
    }
  };

  const handleStalk = async () => {
    console.log("boats in plan ", boatsInPlan);
    const docRef = doc(db, "sim_targets", selectedTarget);
    const docSnap = await getDoc(docRef);

    if (docSnap.exists()) {
      const tgt = docSnap.data();

      sendStalkCommand(selectedTarget, tgt, boatsInPlan);
    } else {
      // docSnap.data() will be undefined in this case
      console.log("No such document!");
    }
  };

  const handleSimTDOA = async () => {
    console.log("in plan ", plan);
    let target = {};
    let receivers = [];

    const docRef = doc(db, "sim_targets", selectedTarget);
    const docSnap = await getDoc(docRef);

    if (docSnap.exists()) {
      const tgt = docSnap.data();
      target = { id: docSnap.id, ...tgt };
    } else {
      // docSnap.data() will be undefined in this case
      console.log("No such document!");
    }

    for (let i in plan?.sim_boats) {
      const boatID = plan?.sim_boats[i];
      const docRef = doc(db, "sim_boats", boatID);
      const docSnap = await getDoc(docRef);

      if (docSnap.exists()) {
        receivers.push({ id: docSnap.id, ...docSnap.data() });
      } else {
        // docSnap.data() will be undefined in this case
        console.log("No such document!");
      }
    }

    const hypers = generateHyperbolicLinePoints(receivers, target);
    const cent = calculateCentroid(hypers);
    dispatch(setTdoaPoint(cent));

    console.log("center ", cent);
    const tdoaJsonLines = {
      type: "FeatureCollection",
      features: hypers.map((line) => ({
        type: "Feature",
        geometry: {
          type: "LineString",
          coordinates: line,
        },
      })),
    };
    dispatch(setTdoaLines(tdoaJsonLines));
    console.log("hypers ", hypers);
  };

  const handleShowHide = () => {
    dispatch(setShowTargets(!showTargets));
  };

  const handleShowTDOALines = () => {
    dispatch(setShowTDOALines(!showTDOALines));
  };

  // useInterval(() => {
  //   if (selectedTarget) {
  //     handleSimTDOA();
  //   }
  // }, 1000);

  return (
    <ThemeProvider theme={darkTheme}>
      <Box sx={{ minWidth: 120, color: "white" }}>
        <Stack spacing={2}>
          <Button variant="contained" onClick={onCreateSimTarget}>
            Create Simulated Target
          </Button>
          <Button variant="contained" onClick={onCreateSimBoat}>
            Create Simulated Boat
          </Button>
          <Button variant="contained" onClick={handleSimTDOA}>
            Simulate TDOA
          </Button>
          <Button variant="contained" onClick={handleShowHide}>
            {showTargets ? "Hide Targets" : "Show Targets"}
          </Button>
          <Button variant="contained" onClick={handleShowTDOALines}>
            {showTDOALines ? "Hide Lines" : "Show Lines"}
          </Button>
          <Typography>{selectedTarget}</Typography>
          <Stack spacing={1} direction="row">
            <Button onClick={handleSetHeading}>Set Heading</Button>
            <Button onClick={handleSetSpeed}>Set Speed</Button>
            <Button onClick={handleSendTrack}>Send Track</Button>{" "}
            <Button onClick={handleMoveTrack}>Move Target</Button>
          </Stack>
          <Button variant="contained" onClick={handleStalk}>
            Stalk
          </Button>
          <Button variant="contained" onClick={handleIntercept}>
            Intercept
          </Button>
        </Stack>
      </Box>
    </ThemeProvider>
  );
}
