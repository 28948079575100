import * as React from "react";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import Box from "@mui/material/Box";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import { useDispatch, useSelector } from "react-redux";
import { setMapMode } from "../data/slices/mapSlice";
import {
  addAvoidZone,
  setActiveAvoidZoneID,
  setAddZonePts,
  setAvoidZones,
} from "../data/slices/planSlice";
import { getFirestore, doc, updateDoc, arrayUnion } from "firebase/firestore";
import { app } from "../api/firebase";
import {
  convertCoordsToObjArray,
  simplifyCoordinates,
} from "../utilities/features";
import { generateString } from "../api/utilities";
import {
  sendMissionGeofence,
  sendMissionAvoidzones,
  sendGotoPt,
  sendPlay,
  sendTask,
} from "../api/plan";
// Create a theme instance.
const darkTheme = createTheme({
  palette: {
    mode: "dark",
  },
});

export default function AvoidZonesControl(props) {
  const { sendAvoidZone } = props;
  const db = getFirestore(app);
  const dispatch = useDispatch();
  const [mission, setMission] = React.useState("contested_logistics");
  const avoidZonePts = useSelector((state) => state.plan.addZonePts);
  const avoidZones = useSelector((state) => state.plan.avoidZones);
  const activeAvoidZoneID = useSelector((state) => state.plan.activeAZID);
  const { currentPlan, boatsInPlan, pickup, dropoff } = useSelector(
    (state) => state.plan
  );
  const assignedSector = useSelector((state) => state.sectors.assignedSector);
  const assignedTeam = useSelector((state) => state.sectors.assignedTeam);
  const onSendAvoidZone = () => {
    let avoidzones = [];
    for (let a of avoidZones) {
      let points = [];
      for (let p of a.coords) {
        points.push({
          x: p[0],
          y: p[1],
          z: 0,
        });
      }
      console.log("Points:", points.length);
      if (points.length > 3) {
        const avoidzone = {
          header: {
            stamp: {
              sec: Math.floor(Date.now() / 1000), // seconds since epoch
              nanosec: (Date.now() % 1000) * 1000000, // nanoseconds
            },
            frame_id: "avoidzone",
          },
          polygon: {
            points: points,
          },
        };
        avoidzones.push(avoidzone);
      }
    }

    const payload = {
      sector: assignedSector.name,
      team: assignedTeam,
      avoidzones: avoidzones,

      // avoidzones: avoidZones.map((z) =>
      //   z.coords.map((c) => `${c[1]}_${c[0]}`).join("|")
      // ),
    };
    console.log("Avoid Zone Payload:", avoidZones);
    sendMissionAvoidzones(payload);
  };

  const onCreateAvoidZone = async () => {
    dispatch(setMapMode("create_avoidzone"));
  };

  const onAddAvoidZone = async () => {
    dispatch(addAvoidZone());
  };

  const onUpdateAvoidZone = async () => {
    const planRef = doc(db, "plans", currentPlan.id);
    let zones = [];
    for (let i in avoidZones) {
      let zone = avoidZones[i];
      let coords = convertCoordsToObjArray(zone.coords);
      zones.push({ id: zone.id, coords: coords });
    }
    await updateDoc(planRef, { avoidZones: zones });
  };

  const updateAvoidzones = async (azs) => {
    const planRef = doc(db, "plans", currentPlan.id);
    let zones = [];
    for (let i in azs) {
      let zone = avoidZones[i];
      let coords = convertCoordsToObjArray(zone.coords);
      zones.push({ id: zone.id, coords: coords });
    }
    await updateDoc(planRef, { avoidZones: zones });
  };

  const onDeleteAvoidZone = async () => {
    console.log("Active Avoid Zone ID:", activeAvoidZoneID);
    console.log("Avoid Zones before deletion:", avoidZones);

    // Filter out the avoid zone that matches the activeAvoidZoneID
    const updatedAvoidZones = avoidZones.filter(
      (zone) => zone.id !== activeAvoidZoneID
    );
    dispatch(setAvoidZones(updatedAvoidZones));
    updateAvoidzones(updatedAvoidZones);
    // Update the avoidZones state or variable here
    // If using React state, for example, you might call this.setState({ avoidZones: updatedAvoidZones })

    //   dispatch(setAddZonePts([]));
    //   dispatch(setActiveAvoidZoneID(null));
    dispatch(setAddZonePts([]));
    dispatch(setActiveAvoidZoneID(null));
    console.log("Avoid Zones after deletion:", updatedAvoidZones);
  };

  return (
    <ThemeProvider theme={darkTheme}>
      <Box sx={{ minWidth: 120, color: "white" }}>
        <Stack spacing={2}>
          <Button variant="contained" onClick={onCreateAvoidZone}>
            Create AvoidZone
          </Button>
          <Button variant="contained" onClick={onAddAvoidZone}>
            Add AvoidZone
          </Button>
          <Button variant="contained" onClick={onDeleteAvoidZone}>
            Delete Selected AvoidZone
          </Button>
          <Button variant="contained" onClick={onUpdateAvoidZone}>
            Update Avoid Zone
          </Button>
          <Button variant="contained" onClick={onSendAvoidZone}>
            Send Avoid Zone To Boats
          </Button>
        </Stack>
      </Box>
    </ThemeProvider>
  );
}
