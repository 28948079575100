import { createSliceWithThunks } from "../../utilities/createSliceWithThunks";
import { app } from "../../api/firebase";
import {
  convertCoordsToObjArray,
  convertObjArrayToCoords,
} from "../../utilities/features";
import {
  getFirestore,
  doc,
  getDoc,
  updateDoc,
  collection,
  addDoc,
  getDocs,
} from "firebase/firestore";
import axios from "axios";
import { url } from "../../api/url";
import { generateString } from "../../api/utilities";

const db = getFirestore(app);

const sectorsSlice = createSliceWithThunks({
  name: "sectors",
  initialState: {
    sectors: [],
    currentSectorPoints: [],
    assignedSector: null,
    assignedTeam: "team1",
  },
  reducers: (create) => ({
    setCurrentSectorPoints: create.reducer((state, action) => {
      state.currentSectorPoints = action.payload;
    }),
    setSectors: create.reducer((state, action) => {
      state.sectors = action.payload;
    }),
    addSector: create.reducer((state, action) => {
      state.sectors.push(action.payload);
    }),
    setAssignedSector: create.reducer((state, action) => {
      console.log("inAssignedSector", action.payload);
      state.assignedSector = action.payload;
    }),
    setAssignedTeam: create.reducer((state, action) => {
      state.assignedTeam = action.payload;
    }),
    saveSectorToDb: create.asyncThunk(
      async (obj, thankAPI) => {
        const converted = convertCoordsToObjArray(obj.sector);

        try {
          const docRef = await addDoc(collection(db, "sectors"), {
            name: obj.name,
            sector: converted,
          });
        } catch (e) {
          console.log("error ", e);
        }

        return obj;
      },
      {
        fulfilled: (state, action) => {
          state.sectors.push(action.payload);
        },
      }
    ),
    saveSectorAndTeamToDB: create.asyncThunk(async (obj, thankAPI) => {
      try {
        await updateDoc(doc(db, "plans", obj.planID), {
          sector: obj.sectorID,
          team: obj.team,
        });
      } catch (e) {}
    }, {}),
    getSectorsFromDb: create.asyncThunk(
      async (obj, thankAPI) => {
        const querySnapshot = await getDocs(collection(db, "sectors"));
        let localSectors = [];
        querySnapshot.forEach((doc) => {
          // doc.data() is never undefined for query doc snapshots
          console.log(doc.id, " => ", doc.data());
          localSectors.push({
            id: doc.id,
            sector: convertObjArrayToCoords(doc.data().sector),
            name: doc.data().name,
          });
        });
        return localSectors;
      },
      {
        fulfilled: (state, action) => {
          state.sectors = action.payload;
          // state.assignedSector = action.payload[0];
        },
      }
    ),
    assignBoat: create.asyncThunk(async (obj, thunkAPI) => {
      console.log("assign boat ", obj);
      const res = await axios.post(`${url}set_assignment`, obj);
      console.log("res ", res);
    }, {}),
  }),
});

export const {
  setCurrentSectorPoints,
  setSectors,
  addSector,
  saveSectorToDb,
  getSectorsFromDb,
  setAssignedSector,
  setAssignedTeam,
  assignBoat,
  saveSectorAndTeamToDB,
} = sectorsSlice.actions;

export default sectorsSlice.reducer;
