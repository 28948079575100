import * as React from "react";
import Box from "@mui/material/Box";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import IconButton from "@mui/material/IconButton";
import DeleteIcon from "@mui/icons-material/Delete";
import HexagonIcon from "@mui/icons-material/Hexagon";
import { removeBoatInPlan } from "../data/slices/planSlice";
import { useSelector, useDispatch } from "react-redux";
export default function PlanBoatList(props) {
  const dispatch = useDispatch();
  const { boats } = props;

  const handleDeleteBoat = (boat) => {
    dispatch(removeBoatInPlan(boat));
  };

  const formatBoat = (boat) => {
    const parts = boat.split("/");
    return parts[1] + " " + parts[3] + " " + parts[5];
  };

  return (
    <Box sx={{ width: "100%", maxWidth: 360, bgcolor: "black" }}>
      <nav aria-label="secondary mailbox folders">
        <List>
          {boats.map((boat, i) => (
            <ListItem
              secondaryAction={
                <IconButton
                  edge="end"
                  aria-label="delete"
                  style={{ color: "white" }}
                  onClick={() => handleDeleteBoat(boat)}
                >
                  <DeleteIcon />
                </IconButton>
              }
              disablePadding
              key={i}
            >
              <ListItemIcon>
                <HexagonIcon color={"success"} />
              </ListItemIcon>
              <ListItemButton>
                <ListItemText
                  primary={formatBoat(boat)}
                  style={{ color: "white" }}
                />
              </ListItemButton>
            </ListItem>
          ))}
        </List>
      </nav>
    </Box>
  );
}
