import React, { useEffect, useState, useRef, useCallback } from "react";
import { Marker, Popup, Source, Layer } from "react-map-gl";
import { useDispatch, useSelector } from "react-redux";
import { doc, onSnapshot, getFirestore } from "firebase/firestore";
import { app } from "../api/firebase";
import { setSelectedTarget } from "../data/slices/planSlice";
import { useInterval } from "../api/useInterval";
import { updatePosition } from "../api/simTargets";
import * as turf from "@turf/turf";
export default function SimTarget({ targetID }) {
  const db = getFirestore(app);
  const [target, setTarget] = useState(null);
  const [circle, setCircle] = useState(null);
  const dispatch = useDispatch();
  const showTargets = useSelector((state) => state.plan.showTargets);
  const subTarget = async (targetID) => {
    const unsub = onSnapshot(doc(db, "sim_targets", targetID), (doc) => {
      // console.log("Current data: ", doc.data());
      setTarget({ id: doc.id, ...doc.data() });
    });
  };
  useEffect(() => {
    subTarget(targetID);
  }, [targetID]);

  useEffect(() => {
    const options = { steps: 64, units: "nauticalmiles" };
    if (target) {
      setCircle(turf.circle([target.lon, target.lat], 1, options));
    }
  }, [target]);

  const onIconClick = async () => {
    dispatch(setSelectedTarget(target.id));
  };

  if (target && showTargets) {
    return (
      <Marker
        latitude={target.lat}
        longitude={target.lon}
        rotation={target.heading}
        onClick={onIconClick}
      >
        <img
          src={"/simtarget.png"}
          style={{ width: 25, height: 50 }}
          alt="Boat"
        />
        {/* {circle && (
          <Source key={targetID} id={targetID} type="geojson" data={circle}>
            <Layer
              id={`circle-layer-${targetID}}`}
              type="fill"
              paint={{
                "fill-color": "red",
                "fill-opacity": 0.3,
              }}
            />
          </Source>
        )} */}
      </Marker>
    );
  } else {
    return <div></div>;
  }
}
