import React from "react";
import { Marker } from "react-map-gl";
import AddLocationIcon from "@mui/icons-material/AddLocation";

const DropoffMarker = ({ lat, lng, ...props }) => {
  return (
    <Marker latitude={lat} longitude={lng} {...props}>
      <div>
        <AddLocationIcon color={"success"} />
      </div>
    </Marker>
  );
};

export default React.memo(DropoffMarker);
