import React from "react";
import ReactMapGL, { Source, Layer } from "react-map-gl";

export default function PlannedPath(props) {
  const geojson = {
    type: "Feature",
    properties: {},
    geometry: {
      type: "LineString",
      coordinates: props.waypoints.map((point) => [point[1], point[0]]), // Swap lat and lon
    },
  };

  return (
    <Source type="geojson" data={geojson}>
      <Layer
        type="line"
        layout={{
          "line-join": "round",
          "line-cap": "round",
        }}
        paint={{
          "line-color": `${props.color}`, // Line color
          "line-width": 2, // Line width
        }}
      />
    </Source>
  );
}
