import { speedDialActionClasses } from "@mui/material";
import { app } from "./firebase";
import {
  getFirestore,
  doc,
  getDoc,
  updateDoc,
  addDoc,
  collection,
  arrayUnion,
} from "firebase/firestore";
import axios from "axios";
import { url } from "./url";

const db = getFirestore(app);
const URL = url;
const createSimTarget = async (lat, lon, heading, speed, planID) => {
  const docRef = await addDoc(collection(db, "sim_targets"), {
    lat: lat,
    lon: lon,
    heading: heading,
    speed: speed,
    mmsi: Math.floor(Math.random() * 10000),
  });

  const planDocRef = doc(db, "plans", planID);
  await updateDoc(planDocRef, {
    sim_targets: arrayUnion(docRef.id),
  });
};

const createSimBoats = async (lat, lon, heading, speed, planID) => {
  const docRef = await addDoc(collection(db, "sim_boats"), {
    lat: lat,
    lon: lon,
    heading: 45,
    speed: speed,
    mmsi: Math.floor(Math.random() * 10000),
  });

  const planDocRef = doc(db, "plans", planID);
  await updateDoc(planDocRef, {
    sim_boats: arrayUnion(docRef.id),
  });
};

function speedHeadingToVelocity(speedInKnots, headingDegrees) {
  // Convert speed from knots to meters per second
  const speedInMetersPerSecond = speedInKnots * 0.514444;

  // Convert heading to radians
  const headingRadians = headingDegrees * (Math.PI / 180);

  // Calculate North and East components
  const vNorth = speedInMetersPerSecond * Math.cos(headingRadians);
  const vEast = speedInMetersPerSecond * Math.sin(headingRadians);

  return { vEast, vNorth };
}

const sendStalkCommand = async (targetID, tgt, boatsInPlan) => {
  for (let i in boatsInPlan) {
    const boat = boatsInPlan[i];
    console.log("boat ", boat);
    const data = {
      msg_type: "intercept",
      boat_id: boat,
      track_id: targetID,
      track_speed: 30,
      task1_auth_proceed: true,
      task1_auth_continue: true,
      task1_offset_distance_m: 300.0,
      task1_respect_avoids: false,
      task1_min_force: 1,
      task2_auth_proceed: false,
      task2_auth_continue: false,
      task2_offset_distance_m: 0.0,
      task2_respect_avoids: false,
      task2_min_force: 1,
    };

    const res = await axios.post(`${URL}/send_interceptor_command`, {
      data: data,
    });
  }
  return "good";
};

const sendInterceptorCommand = async (targetID, tgt, boatsInPlan) => {
  for (let i in boatsInPlan) {
    const boat = boatsInPlan[i];
    console.log("boat ", boat);
    const data = {
      msg_type: "intercept",
      boat_id: boat,
      track_id: targetID,
      track_speed: 20,
      task1_auth_proceed: true,
      task1_auth_continue: true,
      task1_offset_distance_m: 0.0,
      task1_respect_avoids: false,
      task1_min_force: 1,
      task2_auth_proceed: true,
      task2_auth_continue: true,
      task2_offset_distance_m: 0.0,
      task2_respect_avoids: false,
      task2_min_force: 1,
    };

    const res = await axios.post(`${URL}/send_interceptor_command`, {
      data: data,
    });
  }
  return "good";
};

const sendSensorTargetData = async (targetID, updatedTgt) => {
  const { vEast, vNorth } = speedHeadingToVelocity(
    updatedTgt.speed,
    updatedTgt.heading
  );
  const data = {
    msg_type: "sensor",
    uuid: targetID,
    stale: false,
    geo_pose_with_covariance: {
      pose: {
        position: {
          x: updatedTgt.lat * (Math.PI / 180),
          y: updatedTgt.lon * (Math.PI / 180),
          z: 0.0,
        },
        orientation: {
          x: 0.0,
          y: 0.0,
          z: updatedTgt.heading * (Math.PI / 180),
          w: 1.0,
        },
      },
      covariance: [0.0] * 36,
    },
    twist: {
      linear: {
        x: vEast,
        y: vNorth,
        z: 0.0,
      },
      angular: {
        x: 0.0,
        y: 0.0,
        z: 0.0,
      },
    },
    linear_velocity_valid: true,
    angular_velocity_valid: false,
    meta_is_neighbor: false,
    meta_neighbor_boat_id: "",
    meta_mmsi: updatedTgt.mmsi,
    meta_ais_callsign: "",
    meta_ais_status: 0,
    meta_ship_dim_to_bow_m: 0,
    meta_ship_dim_to_stern_m: 0,
    meta_ship_dim_to_port_m: 0,
    meta_ship_dim_to_stbd_m: 0,
  };

  const res = await axios.post(`${URL}/send_sensor_data`, { data: data });
  return res;
};

function updatePosition(boat) {
  // Constants
  const R = 6371; // Earth's radius in kilometers
  const degToRad = Math.PI / 180; // Conversion factor from degrees to radians

  // Convert speed to km/h (assuming speed is given in nautical miles per hour)
  const speedKmPerHour = boat.speed * 1.852;

  // Assuming the movement duration is 1 hour for simplicity
  // For different durations, multiply speedKmPerHour by the duration in hours
  const distance = speedKmPerHour / 3600; // Distance covered in one hour

  // Convert heading to radians
  const headingRadians = boat.heading * degToRad;

  // Calculate displacement
  const displacementLat = (distance * Math.cos(headingRadians)) / R;
  const displacementLon =
    (distance * Math.sin(headingRadians)) / (R * Math.cos(boat.lat * degToRad));

  // Convert displacement from radians to degrees
  const deltaLat = displacementLat * (180 / Math.PI);
  const deltaLon = displacementLon * (180 / Math.PI);

  // Update the boat's position
  boat.lat += deltaLat;
  boat.lon += deltaLon;

  return boat; // Return the updated boat object
}

const handleMoveTrack = async (selectedTarget, lat, lon) => {
  const docRef = doc(db, "sim_targets", selectedTarget);
  updateDoc(docRef, { lat: lat, lon: lon });
};

export {
  createSimTarget,
  updatePosition,
  sendSensorTargetData,
  sendInterceptorCommand,
  sendStalkCommand,
  createSimBoats,
  handleMoveTrack,
};
