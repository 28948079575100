import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getAuth } from "firebase/auth";
import {
  Box,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Typography,
  Button,
} from "@mui/material";
import {
  collection,
  query,
  where,
  onSnapshot,
  getFirestore,
  updateDoc,
  doc,
} from "firebase/firestore";
import { app } from "../api/firebase";
import {
  getUpSims,
  setSims,
  deleteDownSims,
  createSITLRequest,
} from "../data/slices/simSlice";
import axios from "axios";
import { url } from "../api/url";

function SITLDashboard() {
  const auth = getAuth(app);
  const dispatch = useDispatch();
  const db = getFirestore(app);
  const sims = useSelector((state) => state.sim.sims);
  const simID = useSelector((state) => state.sim.simID);

  useEffect(() => {
    dispatch(getUpSims());
    // This effect does not depend on changing values and is meant to run only once on component mount.
  }, [dispatch]);

  useEffect(() => {
    if (!simID) return;
    console.log("simID", simID);
    const dbRef = collection(db, "sitl_sims");
    const q = query(
      dbRef,
      where("up", "==", true),
      where("request", "==", simID),
      where("response", "==", simID)
    );
    const unsubscribe = onSnapshot(q, (querySnapshot) => {
      const simsLocal = querySnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      dispatch(setSims(simsLocal));
    });

    return () => unsubscribe(); // Cleanup on component unmount or simID change.
  }, [simID, db, dispatch]);

  const handleCommand = async (simId, command, value) => {
    const docRef = doc(db, "sitl_sims", simId);
    console.log("in handleCommand", simId, command, value);
    const updateData =
      command === "boat_id" || command === "url"
        ? { [command]: value }
        : { command: value };
    await updateDoc(docRef, updateData);

    // dispatch(getUpSims());
  };

  const resetCloudStatus = async (boatID) => {
    axios.get(url + "reset_cloud_status/" + boatID);
  };

  return (
    <Box>
      <Stack spacing={2} alignItems="center">
        <Typography variant="h3">SITL Dashboard</Typography>
        <Stack spacing={2} direction={"row"}>
          <Button onClick={() => dispatch(getUpSims())}>
            Request SITL Statuses
          </Button>
          <Button onClick={() => dispatch(deleteDownSims())}>
            Delete Down Sims
          </Button>
          <Button onClick={() => auth.signOut()}>Logout</Button>
        </Stack>

        <TableContainer component={Paper} style={{ width: "80%" }}>
          <Table sx={{ minWidth: 400 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>HITL</TableCell>
                <TableCell>Boat ID</TableCell>
                <TableCell>SITL URL</TableCell>
                <TableCell>Up Status</TableCell>
                <TableCell>Ros</TableCell>
                <TableCell>Upload Logs</TableCell>
                <TableCell>Commands</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {sims.map((sim) => (
                <TableRow
                  key={sim.id}
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell>{sim.hitl ? "True" : "False"}</TableCell>
                  <TableCell>{sim.boat_id}</TableCell>
                  <TableCell>{sim?.url}</TableCell>
                  <TableCell>{sim.up ? "Up" : "Down"}</TableCell>
                  <TableCell>{sim.ros_status ? "Up" : "Down"}</TableCell>
                  <TableCell>
                    {sim.upload_logs ? (
                      <a href={sim.upload_logs} download>
                        Download Logs
                      </a>
                    ) : (
                      "No logs available"
                    )}
                  </TableCell>
                  <TableCell>
                    <Button
                      onClick={() => {
                        handleCommand(sim.id, "command", "start");
                        resetCloudStatus(sim.boat_id);
                      }}
                    >
                      Start
                    </Button>
                    <Button
                      onClick={() => handleCommand(sim.id, "command", "stop")}
                    >
                      Stop
                    </Button>
                    <Button
                      onClick={() =>
                        handleCommand(sim.id, "boat_id", prompt("Enter BoatID"))
                      }
                    >
                      Set ID
                    </Button>
                    <Button
                      onClick={() =>
                        handleCommand(sim.id, "url", prompt("Enter SITL URL"))
                      }
                    >
                      Set URL
                    </Button>
                    <Button
                      onClick={() =>
                        handleCommand(sim.id, "command", "ros_status")
                      }
                    >
                      Get Status
                    </Button>
                    <Button
                      onClick={() =>
                        handleCommand(sim.id, "command", "upload_logs")
                      }
                    >
                      Get Logs
                    </Button>
                    <Button
                      onClick={() =>
                        dispatch(
                          createSITLRequest({
                            location: prompt("Enter Location ID"),
                            id: sim.id,
                          })
                        )
                      }
                    >
                      Get Sim
                    </Button>
                    <Button onClick={() => resetCloudStatus(sim.boat_id)}>
                      Test Reset
                    </Button>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Stack>
    </Box>
  );
}

export default SITLDashboard;
