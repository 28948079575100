import React, { useEffect, useState } from "react";
import { Marker } from "react-map-gl";
import { app } from "../api/firebase";
import {
  collection,
  getFirestore,
  onSnapshot,
  query,
  where,
} from "firebase/firestore";

const ATAKTracks = (props) => {
  const [tracks, setTracks] = useState([]);
  const db = getFirestore(app);

  const subATAKTracks = async () => {
    const q = query(collection(db, "atak_tracks"));

    const unsub = onSnapshot(q, (querySnapshot) => {
      const localTracks = [];
      querySnapshot.forEach((doc) => {
        const track = { id: doc.id, ...doc.data() };
        if (track.sendTime <= track.staleTime) {
          console.log(track);
          localTracks.push(track);
        }
      });
      setTracks(localTracks);
    });

    return () => unsub();
  };

  useEffect(() => {
    subATAKTracks();
  }, []);

  return (
    <div>
      {tracks.map((track, index) => (
        <Marker
          key={index}
          latitude={track.lat}
          longitude={track.lon}
          offsetLeft={-20}
          offsetTop={-10}
        >
          <div
            style={{
              background: "white",
              color: "white",
              padding: "5px",
              borderRadius: "50%",
              width: "10px",
              height: "10px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          ></div>
          <div
            style={{ marginTop: "5px", textAlign: "center", color: "white" }}
          >
            {track.callsign}
          </div>
        </Marker>
      ))}
    </div>
  );
};

export default ATAKTracks;
