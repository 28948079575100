import * as React from "react";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Slider from "@mui/material/Slider";
import VolumeDown from "@mui/icons-material/VolumeDown";
import VolumeUp from "@mui/icons-material/VolumeUp";
import { Typography } from "@mui/material";
export default function SpeedSlider(props) {
  const { speed, setSpeed } = props;

  const handleChange = (event, newValue) => {
    setSpeed(newValue);
  };

  return (
    <Box>
      <Stack spacing={2} direction="column" sx={{ mb: 1 }} alignItems="center">
        <Typography style={{ color: "white" }}>Speed (knots)</Typography>
        <Slider
          aria-label="Speed"
          value={speed}
          onChange={handleChange}
          defaultValue={5}
          marks
          min={1}
          max={20}
          step={1}
          valueLabelDisplay="on"
        />
      </Stack>
    </Box>
  );
}
