import React, { useEffect, useState } from "react";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import Box from "@mui/material/Box";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import { useDispatch, useSelector } from "react-redux";
import { setMapMode } from "../data/slices/mapSlice";
import { deleteBoat } from "../data/slices/planSlice";
import { Typography } from "@mui/material";
import { sendPlay, sendRTL, sendDisarm } from "../api/plan";
import ManageBoatList from "./ManageBoatList";
import { assignBoat } from "../data/slices/sectorsSlice";
// Create a theme instance.
const darkTheme = createTheme({
  palette: {
    mode: "dark",
  },
});

export default function TestPanel() {
  const dispatch = useDispatch();
  const { directPoint } = useSelector((state) => state.plan);
  const assignedSector = useSelector((state) => state.sectors.assignedSector);
  const assignedTeam = useSelector((state) => state.sectors.assignedTeam);
  const activeAsset = useSelector((state) => state.map.activeAsset);
  const { boatsInPlan } = useSelector((state) => state.plan);
  const sendLocalPlay = () => {
    let task = {
      task_id: 1,
      geo_pose: [
        {
          position: {
            longitude: directPoint.lng,
            latitude: directPoint.lat,
            altitude: 0.0,
          },
          orientation: {
            x: 0.0,
            y: 0.0,
            z: 0.0,
            w: 1.0,
          },
        },
      ],
      area: [],
      auth_proceed: true,
      auth_continue: true,
      reference_track_uuid: "",
      offset_distance_m: 0.0,
      target_speed_mps: 5.0,
      respect_avoids: true,
      min_force: 1,
      goal_reach_tolerance_m: 10.0,
    };
    let play = {
      header: {
        stamp: {
          sec: Math.floor(Date.now() / 1000), // seconds since epoch
          nanosec: (Date.now() % 1000) * 1000000, // nanoseconds
        },
        frame_id: "avoidzone",
      },
      playbook_version: 1,
      play_id: 1,
      task_parameters: [task],
    };
    let data = {
      team: assignedTeam,
      sector: assignedSector.name,
      play: play,
    };

    console.log("data ", data);
    sendPlay(data);
  };

  const setDirectPoint = () => {
    dispatch(setMapMode("setDirectPoint"));
  };

  const onDeleteAsset = () => {
    console.log("on delete ", activeAsset);
    dispatch(deleteBoat({ boat_string: activeAsset }));
  };

  const onSendRTL = () => {
    sendRTL({ boatID: activeAsset });
  };

  const onSendDisarm = () => {
    sendDisarm({ boatID: activeAsset });
  };

  const onAssignAllBoats = async () => {
    for (let i = 0; i < boatsInPlan.length; i++) {
      dispatch(
        assignBoat({
          boatID: boatsInPlan[i].split("/")[5],
          team: assignedTeam,
          sector: assignedSector.name,
        })
      );
      dispatch(deleteBoat({ boat_string: boatsInPlan[i] }));
    }
    for (let i = 0; i < boatsInPlan.length; i++) {
      dispatch(
        assignBoat({
          boatID: boatsInPlan[i].split("/")[5],
          team: assignedTeam,
          sector: assignedSector.name,
        })
      );
      dispatch(deleteBoat({ boat_string: boatsInPlan[i] }));
    }
    setTimeout(window.location.reload(), 5000);
  };

  return (
    <ThemeProvider theme={darkTheme}>
      <Box sx={{ minWidth: 120, color: "white" }}>
        <Stack spacing={1} justifyContent="center" alignItems="center">
          <Stack spacing={1} justifyContent="center" alignItems="center">
            <Typography style={{ color: "white" }}>
              Assigned Sector: {assignedSector?.name}
            </Typography>
            <Typography style={{ color: "white" }}>
              Assigned Team: {assignedTeam}
            </Typography>
          </Stack>

          <Button variant="contained" onClick={onDeleteAsset}>
            Delete Boat
          </Button>
          <Button variant="contained" onClick={onSendRTL}>
            Send RTL
          </Button>
          <Button variant="contained" onClick={onSendDisarm}>
            Send Disarm
          </Button>
          <Button variant="contained" onClick={onAssignAllBoats}>
            Assign All Boats to Current Team
          </Button>
          <Typography style={{ color: "white" }}>
            Boats Under Control
          </Typography>
          {boatsInPlan.length > 0 && <ManageBoatList boats={boatsInPlan} />}
        </Stack>
      </Box>
    </ThemeProvider>
  );
}
