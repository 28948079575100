import * as React from "react";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import Divider from "@mui/material/Divider";
import { Typography } from "@mui/material";
import CreateSimModal from "./CreateSimModal";
import { useSelector, useDispatch } from "react-redux";
import {
  getSims,
  requestSimInstance,
  requestStopAllInstances,
  setHavocSims,
  setSimCreateOpen,
  stopSim,
} from "../data/slices/simSlice";
import Box from "@mui/material/Box";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import IconButton from "@mui/material/IconButton";
import DeleteIcon from "@mui/icons-material/Delete";
import InboxIcon from "@mui/icons-material/Inbox";
import DraftsIcon from "@mui/icons-material/Drafts";
import { act } from "react-dom/test-utils";
import { onSnapshot, collection, getFirestore, doc } from "firebase/firestore";
import { app } from "../api/firebase";

function App() {
  const db = getFirestore(app);
  const [activeSimID, setActiveSimID] = React.useState(null);
  const [runningSims, setRunningSims] = React.useState([]);
  const dispatch = useDispatch();
  const havocSims = useSelector((state) => state.sim.havocSims);
  const simCreateOpen = useSelector((state) => state.sim.simCreateOpen);
  const requestID = useSelector((state) => state.sim.requestID);
  const user = useSelector((state) => state.user.user);

  React.useEffect(() => {
    dispatch(getSims());
  }, []);

  React.useEffect(() => {
    const unsub = onSnapshot(collection(db, "running_sims"), (snapshot) => {
      const data = snapshot.docs.map((doc) => {
        return { id: doc.id, ...doc.data() };
      });

      console.log("Running sims", data);
      setRunningSims(data);
    });
    return () => {
      unsub();
    };
  }, []);

  React.useEffect(() => {
    if (requestID) {
      console.log("Request ID", requestID);
      const unsub = onSnapshot(doc(db, "havoc_sims", requestID.id), (doc) => {
        dispatch(setHavocSims(doc.data().up_sims));
        console.log("Current data: ", doc.data());
      });
      return () => {
        unsub();
      };
    }
  }, [requestID]);

  return (
    <div>
      <Stack
        spacing={2}
        direction="column"
        justifyContent="center"
        alignItems="center"
        style={{ marginLeft: 50, marginRight: 50 }}
      >
        <img src={"HavocLogo.png"} style={{ height: 100 }} />

        <Typography>
          Step 1: Click on the Sim ID under Available Sims to view the running
          simulation. Remember the Sim ID!
        </Typography>
        <Typography>Available Sims</Typography>

        <List>
          {havocSims?.map((id) => (
            <ListItem disablePadding key={id}>
              <ListItemButton
                onClick={() => {
                  setActiveSimID(id);
                  dispatch(setSimCreateOpen(true));
                }}
              >
                <ListItemText primary={id} />
              </ListItemButton>
            </ListItem>
          ))}
        </List>
        <Divider style={{ width: "100%" }} />
        <Typography>
          Step 2: Click on the Sim ID once it is Green (Sim Setup Complete). Red
          means the system is still setting up the simulation. Clicking below
          will open a new tab with the simulation loaded.
        </Typography>
        <Typography>Running Sims</Typography>
        <List>
          {runningSims?.map((sim) => (
            <ListItem
              disablePadding
              key={sim.planID}
              secondaryAction={
                <IconButton
                  edge="end"
                  aria-label="delete"
                  onClick={() => dispatch(stopSim(sim))}
                >
                  <DeleteIcon />
                </IconButton>
              }
            >
              <ListItemButton
                onClick={() => {
                  const currentDomain = window.location.href;
                  window.open(currentDomain + "plan/" + sim.planID, "_blank");
                }}
              >
                <ListItemText
                  primary={
                    <Typography
                      style={{
                        color: sim.status === "ready" ? "green" : "red",
                      }}
                    >
                      {sim.id}
                    </Typography>
                  }
                />
              </ListItemButton>
            </ListItem>
          ))}
        </List>
        <Divider style={{ width: "100%" }} />
        <Typography>
          Step 3: Please remember to shut down your cloud instances when you are
          done. Before shutting down, click the 'Trash' icon next to the SimIDs
          you started to clean up the database.
        </Typography>

        <CreateSimModal open={simCreateOpen} activeSimID={activeSimID} />
      </Stack>
    </div>
  );
}

export default App;
