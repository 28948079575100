import React, { useEffect, useState, useRef, useCallback } from "react";
import { Marker, Popup, Source, Layer } from "react-map-gl";
import { useDispatch } from "react-redux";
import { doc, onSnapshot, getFirestore } from "firebase/firestore";
import { app } from "../api/firebase";
import { setSelectedTarget, setSimBoat } from "../data/slices/planSlice";
import { useInterval } from "../api/useInterval";
import { updatePosition } from "../api/simTargets";
import * as turf from "@turf/turf";
export default function SimBoat({ boatID }) {
  const db = getFirestore(app);
  const dispatch = useDispatch();
  const [boat, setBoat] = useState(null);
  const [circle, setCircle] = useState(null);
  const subBoat = async (targetID) => {
    const unsub = onSnapshot(doc(db, "sim_boats", boatID), (doc) => {
      // console.log("Current data: ", doc.data());

      setBoat({ id: doc.id, ...doc.data() });
    });
  };

  useEffect(() => {
    subBoat(boatID);
  }, [boatID]);

  useEffect(() => {
    const options = { steps: 64, units: "nauticalmiles" };
    if (boat?.lat && boat?.lon) {
      setCircle(turf.circle([boat.lon, boat.lat], 5, options));
      //dispatch(setSimBoat(boat));
    }
  }, [boat?.lat, boat?.lon, setCircle, dispatch]);

  if (boat) {
    return (
      <Marker latitude={boat.lat} longitude={boat.lon} rotation={boat.heading}>
        <img
          src={"/havocboat.png"}
          style={{ width: 25, height: 50 }}
          alt="Boat"
        />

      </Marker>
    );
  } else {
    return <div></div>;
  }
}
