/**
 * The MissionPlan component serves as a comprehensive interactive planning tool for mission
 * management within a maritime or logistics application. This component integrates mapping,
 * geospatial manipulation, and real-time asset tracking functionalities to facilitate complex
 * mission planning tasks such as setting pickup and dropoff locations, defining geofences,
 * avoiding zones, and simulating asset movements.
 *
 * Dependencies:
 * - React for component structuring and lifecycle management.
 * - Material-UI for the user interface components and icons, providing a responsive and
 *   accessible user experience.
 * - React-Map-GL for the interactive mapping functionalities, including markers, sources, and layers.
 * - Redux for global state management, allowing for the tracking and updating of mission-related
 *   information across the application.
 * - Firebase Firestore for real-time data storage and retrieval, ensuring that mission data is
 *   consistently synchronized with the backend.
 * - Turf.js for geospatial analysis, supporting operations such as point-in-polygon checks.
 *
 * Key Functionalities:
 * - Dynamic map rendering with interactive controls for navigating and modifying the geographical
 *   context of a mission.
 * - Real-time updating and manipulation of mission parameters such as geofences, avoid zones, and
 *   simulated entities (boats, targets).
 * - Integration with Firebase Firestore for the retrieval and real-time update of mission data.
 * - Comprehensive state management through Redux, facilitating the interaction between user actions,
 *   map modifications, and application state.
 * - Utilization of custom hooks for efficient data fetching and state synchronization.
 *
 * Usage:
 * This component is intended to be used within the context of a single-page application (SPA)
 * for mission planning and management. It is accessed through a specific route parameter (planID),
 * enabling the component to fetch and display relevant mission data.
 *
 * Notes:
 * Ensure that environment variables for mapbox-gl (e.g., access token) and Firebase are correctly
 * set up and secured. Due to the complex interactions between user actions, map events, and state
 * changes, careful consideration should be given to performance optimizations and re-render minimization.
 */

import React, { useState, useCallback, useRef, useEffect } from "react";
import { useParams } from "react-router-dom";
import { getFirestore } from "firebase/firestore";
// Material-UI Components and Icons
import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";

// React-Map-GL and Related
import Map, {
  Marker,
  Source,
  Layer,
  NavigationControl,
  ScaleControl,
} from "react-map-gl";
import "mapbox-gl/dist/mapbox-gl.css";

// Redux and State Management
import { useDispatch, useSelector } from "react-redux";
import {
  addPickupLocation,
  addDropoffLocation,
  addGeofencePoint,
  setGeofencePoints,
  addZonePts,
  setPlannerID,
  setAddZonePts,
  setActiveAvoidZoneID,
  updateAvoidZone,
  setPickupTerminalArea,
  setDropoffTerminalArea,
  setDirectPoint,
  addSearchAreaPts,
  setTestTrackPt,
  getBoatsInMissionArea,
} from "../data/slices/planSlice";
import { setCurrentSectorPoints } from "../data/slices/sectorsSlice";
import { setActiveFeature } from "../data/slices/featureSlice";
import { setMapMode } from "../data/slices/mapSlice";
import { usePlanData } from "../hooks/usePlanData";

// Utility Imports

import { point, booleanPointInPolygon } from "@turf/turf";

// Local Components

import RightMissionPlanPanel from "../components/RightMissionPlanPanel";
import PickupMarker from "../components/PickupMarker";
import DropoffMarker from "../components/DropoffMarker";
import ATAKTracks from "../components/ATAKTracks";
import AvoidZones from "../components/AvoidZones";
import Boat from "../components/Boat";
import PlannedPath from "../components/PlannedPath";
import { sendGotoPt } from "../api/plan";

// Styles and CSS
import myGeoJSON from "../geodata/demo0.json";
import {
  createSimBoats,
  createSimTarget,
  handleMoveTrack,
} from "../api/simTargets";
import SimTarget from "../components/SimTarget";

import { app } from "../api/firebase";
import SimBoat from "../components/SimBoat";
import { createBoundingBoxWithOnePoint } from "../api/utilities";
import { getTerminalAreas } from "../data/slices/terminalSlice";
import TerminalAreas from "../components/TerminalAreas";
import SearchArea from "../components/SearchArea";
const turf = require("@turf/turf");

export default function MissionPlan(props) {
  const db = getFirestore(app);
  const { planID } = useParams();
  const dispatch = useDispatch();
  const { plan } = usePlanData();
  const [initComplete, setInitCoplete] = useState(false);
  const boatsInPlan = useSelector((state) => state.plan.boatsInPlan);
  const geofence = useSelector((state) => state.feature.missionGeofence);
  const avoidZones = useSelector((state) => state.plan.avoidZones);
  const mapMode = useSelector((state) => state.map.mapMode);
  const pickup = useSelector((state) => state.plan.pickup);
  const dropoff = useSelector((state) => state.plan.dropoff);
  const activeAsset = useSelector((state) => state.map.activeAsset);
  const waypoints = useSelector((state) => state.plan.waypoints);
  const geofencePts = useSelector((state) => state.plan.geofence);
  const avoidZonePts = useSelector((state) => state.plan.addZonePts);
  const tdoaLines = useSelector((state) => state.plan.tdoaLines);
  const simBoats = useSelector((state) => state.plan.simBoats);
  const selectedTarget = useSelector((state) => state.plan.selectedTarget);
  const tdoaPoint = useSelector((state) => state.plan.tdoaPoint);
  const plannerID = useSelector((state) => state.plan.plannerID);
  const showTDOALines = useSelector((state) => state.plan.showTDOALines);
  const activeAvoidZoneID = useSelector((state) => state.plan.activeAZID);
  const sectors = useSelector((state) => state.sectors.sectors);
  const terminalAreas = useSelector((state) => state.terminal.terminalAreas);
  const activeStep = useSelector((state) => state.plan.activeStep);
  const searchAreaPoints = useSelector((state) => state.plan.searchAreaPts);
  const testTrackPt = useSelector((state) => state.plan.testTrackPt);
  const directPoint = useSelector((state) => state.plan.directPoint);
  const currentSectorPoints = useSelector(
    (state) => state.sectors.currentSectorPoints
  );
  const missionType = useSelector((state) => state.plan.missionType);
  const [viewport, setViewport] = useState({
    latitude: 37.7577,
    longitude: -122.4376,
    zoom: 12,
    bearing: 0,
  });
  const [clickedLand, setClickLand] = useState(false);
  const mapRef = useRef();

  useEffect(() => {
    if (!initComplete && plan) {
      setViewport({
        latitude: plan.center.lat,
        longitude: plan.center.lng,
        zoom: 12,
        bearing: 0,
      });
      let area = createBoundingBoxWithOnePoint({
        lat: plan.center.lat,
        lng: plan.center.lng,
      });
      dispatch(setGeofencePoints(area));
      setInitCoplete(true);
    }
  }, [initComplete, plan]);

  <NavigationControl visualizePitch={true} />;

  const handleMapClick = useCallback(
    (e) => {
      const clickedPoint = point([e.lngLat.lng, e.lngLat.lat]);
      const avoidIDs = avoidZones.map((zone) => zone.id);
      const features = mapRef.current.queryRenderedFeatures(e.point, {
        layers: avoidIDs,
      });
      const clickedZone = avoidZones.find(
        (zone) => zone.id === features[0]?.layer?.id
      );

      if (clickedZone) {
        dispatch(setAddZonePts(clickedZone.coords));
        dispatch(setActiveAvoidZoneID(clickedZone.id));
      }
      // } else {
      //   console.log("no az");
      //   dispatch(setAddZonePts([]));
      //   dispatch(setActiveAvoidZoneID(null));
      // }

      let terminalIDs = terminalAreas.map((zone) => zone.id);
      let terminalFeatures = mapRef.current.queryRenderedFeatures(e.point, {
        layers: terminalIDs,
      });
      let clickedTerminal = terminalAreas.find(
        (zone) => zone.id === terminalFeatures[0]?.layer?.id
      );

      const isInside = myGeoJSON.geometries.some(
        (geometry) =>
          geometry.type === "Polygon" &&
          booleanPointInPolygon(clickedPoint, geometry)
      );

      handleMapModeActions(e, isInside, clickedTerminal);
      setClickLand(isInside);

      // Reset map mode for certain conditions
      if (
        mapMode !== "create_geofence" &&
        mapMode !== "create_avoidzone" &&
        mapMode !== "addSearchAreaPoint"
      ) {
        dispatch(setMapMode("normal"));
      }
    },
    [
      avoidZones,
      mapMode,
      dispatch,
      planID,
      selectedTarget,
      plan?.id,
      activeAsset,
      myGeoJSON,
    ]
  );

  // Extracted actions based on mapMode
  function handleMapModeActions(e, isInside, clickedTerminal) {
    switch (mapMode) {
      case "addTestTrackPoint":
        dispatch(setTestTrackPt([e.lngLat.lng, e.lngLat.lat]));
        break;
      case "interceptorSelectMissionArea":
        let area = createBoundingBoxWithOnePoint({
          lat: e.lngLat.lat,
          lng: e.lngLat.lng,
        });
        dispatch(setGeofencePoints(area));
        console.log("area ", area);
        break;
      case "addSearchAreaPoint":
        dispatch(addSearchAreaPts([e.lngLat.lng, e.lngLat.lat]));
        console.log("in add search area point");
        break;
      case "setDirectPoint":
        dispatch(setDirectPoint({ lat: e.lngLat.lat, lng: e.lngLat.lng }));
        break;
      case "pickup":
      case "dropoff":
        if (!isInside && clickedTerminal) {
          const action =
            mapMode === "pickup" ? addPickupLocation : addDropoffLocation;
          dispatch(
            action({
              lat: e.lngLat.lat,
              lng: e.lngLat.lng,
              id: plan.id,
            })
          );
          const terminalAction =
            mapMode === "pickup"
              ? setPickupTerminalArea
              : setDropoffTerminalArea;
          dispatch(terminalAction(clickedTerminal));
        }
        break;
      case "gotopoint":
        if (!isInside) {
          sendGotoPt({ id: activeAsset, lat: e.lngLat.lat, lon: e.lngLat.lng });
        }
        break;
      case "create_geofence":
        dispatch(addGeofencePoint([e.lngLat.lng, e.lngLat.lat]));
        break;
      case "create_avoidzone":
        dispatch(addZonePts([e.lngLat.lng, e.lngLat.lat]));
        break;
      case "create_sim_target":
        createSimTarget(e.lngLat.lat, e.lngLat.lng, 0, 0, planID);
        break;
      case "create_sim_boat":
        createSimBoats(e.lngLat.lat, e.lngLat.lng, 0, 0, planID);
        break;
      case "move_sim_track":
        handleMoveTrack(selectedTarget, e.lngLat.lat, e.lngLat.lng);
        break;
      case "create_sector":
        const sectorBox = createBoundingBoxWithOnePoint(
          { lat: e.lngLat.lat, lng: e.lngLat.lng },
          20
        );
        console.log("sectorBox ", sectorBox);
        dispatch(setCurrentSectorPoints(sectorBox));
        break;
      // No default needed if all cases are covered
    }
  }

  const BoatMarker = React.memo(({ id, boat }) => {
    return <Boat key={id} boatID={boat} viewport={viewport} />;
  });

  const SimBoatMarker = React.memo(({ id, boat }) => {
    return <SimBoat key={id} boatID={boat} />;
  });

  useEffect(() => {
    dispatch(setPlannerID());
  }, [dispatch]);

  useEffect(() => {
    dispatch(getTerminalAreas());
  }, []);

  useEffect(() => {
    if (geofencePts.length > 0) {
      dispatch(getBoatsInMissionArea(geofencePts));
    }
  }, [geofencePts]);

  // Define the polygon as a GeoJSON object
  const geofenceData = {
    type: "Feature",
    properties: {},
    geometry: {
      type: "Polygon",
      coordinates: [geofencePts],
    },
  };

  // Define the style for the polygon layer
  const geofenceLayerStyle = {
    id: "polygon",
    type: "fill",
    paint: {
      "fill-color": "#088", // Fill color of the polygon
      "fill-opacity": 0.05, // Fill opacity of the polygon
    },
  };

  const sectorData = {
    type: "Feature",
    properties: {},
    geometry: {
      type: "Polygon",
      coordinates: [currentSectorPoints],
    },
  };
  // Define the style for the polygon layer
  const sectorLayerStyle = {
    id: "polygon",
    type: "fill",
    paint: {
      "fill-color": "yellow", // Fill color of the polygon
      "fill-opacity": 0.05, // Fill opacity of the polygon
    },
  };

  useEffect(() => {
    console.log("terminalAreas ", terminalAreas);
  }, [terminalAreas]);

  // Function to update marker position on drag end
  const onMarkerDragEnd = (event, index) => {
    const updatedGeofencePts = geofencePts.map((pt, idx) => {
      if (idx === index) {
        return [event.lngLat.lng, event.lngLat.lat]; // Update the specific marker's position
      }
      return pt; // Return other points as they are
    });

    dispatch(setGeofencePoints(updatedGeofencePts)); // Update the state with the new points
  };

  const onAZDragEnd = (event, index) => {
    const updatedAvoidZonePts = avoidZonePts.map((pt, idx) => {
      if (idx === index) {
        return [event.lngLat.lng, event.lngLat.lat]; // Update the specific marker's position
      }
      return pt; // Return other points as they are
    });

    dispatch(setAddZonePts(updatedAvoidZonePts)); // Update the state with the new points
    let clickedZone = avoidZones.find(
      (object) => object.id === activeAvoidZoneID
    );

    dispatch(
      updateAvoidZone({ id: activeAvoidZoneID, coords: updatedAvoidZonePts })
    );

    console.log(avoidZones, activeAvoidZoneID, clickedZone);
  };

  if (plan) {
    return (
      <Stack direction={"row"} alignItems={"center"}>
        <Box sx={{ width: "80%", height: "100%" }}>
          <Stack
            spacing={2}
            style={{ backgroundColor: "black", marginTop: 30 }}
          >
            <Map
              {...viewport}
              onViewportChange={(nextViewport) => setViewport(nextViewport)}
              mapLib={import("mapbox-gl")}
              mapboxAccessToken="pk.eyJ1IjoicGx3aW4yMDA3IiwiYSI6ImNsbjBqamg5aTFkY3oycW83dDg2c2dja20ifQ.Fvas3o3mJGgRlb9z3eFfaA"
              onMove={(evt) => setViewport(evt.viewState)}
              style={{ width: "100%", height: 1000 }}
              mapStyle="mapbox://styles/mapbox/satellite-v9"
              onClick={handleMapClick}
              ref={mapRef}
              onContextMenu={() => {
                dispatch(setMapMode("normal"));
              }}
              minPitch={0}
              maxPitch={0}
            >
              <NavigationControl />
              <ScaleControl
                maxWidth={100}
                unit="imperial"
                position={"top-left"}
              />
              {boatsInPlan.map((boat, id) => (
                <BoatMarker key={id} boat={boat} viewport={viewport} />
              ))}

              <Source type="geojson" data={myGeoJSON}>
                <Layer
                  id="shapefile-layer"
                  type="fill"
                  paint={{ "fill-color": "red", "fill-opacity": 0.2 }}
                />
              </Source>

              {missionType === "sector" && (
                <Source type="geojson" data={sectorData}>
                  <Layer
                    id="sector-layer"
                    type="fill"
                    paint={{ "fill-color": "yellow", "fill-opacity": 0.1 }}
                  />
                </Source>
              )}

              <AvoidZones avoidZones={avoidZones} />

              {pickup && <PickupMarker lat={pickup.lat} lng={pickup.lng} />}
              {dropoff && <DropoffMarker lat={dropoff.lat} lng={dropoff.lng} />}
              {waypoints && (
                <PlannedPath waypoints={waypoints} color="yellow" />
              )}
              {missionType != "sector" &&
                geofencePts.map((geoPt, index) => (
                  <Marker
                    key={index}
                    latitude={geoPt[1]}
                    longitude={geoPt[0]}
                    color={"yellow"}
                    draggable
                    onDragEnd={(event) => onMarkerDragEnd(event, index)}
                  ></Marker>
                ))}
              {avoidZonePts.map((addZonePt, index) => (
                <Marker
                  key={index}
                  latitude={addZonePt[1]}
                  longitude={addZonePt[0]}
                  color={"red"}
                  draggable
                  onDragEnd={(event) => onAZDragEnd(event, index)}
                ></Marker>
              ))}
              {plan?.sim_targets?.map((target, index) => (
                <SimTarget key={index} targetID={target} />
              ))}
              {plan?.sim_boats?.map((boat, id) => (
                <SimBoatMarker key={id} boat={boat} />
              ))}
              {missionType != "sector" && (
                <Source id="polygonSource" type="geojson" data={geofenceData}>
                  <Layer {...geofenceLayerStyle} />
                </Source>
              )}

              {testTrackPt.length > 1 && (
                <Marker
                  latitude={testTrackPt[1]}
                  longitude={testTrackPt[0]}
                  color={"red"}
                ></Marker>
              )}
              {directPoint && (
                <Marker
                  latitude={directPoint.lat}
                  longitude={directPoint.lng}
                  color={"yellow"}
                ></Marker>
              )}
              {/* {searchAreaPoints.length > 0 && <SearchArea />} */}
              {activeStep === 0 && <TerminalAreas />}
              {searchAreaPoints.length > 0 && <SearchArea />}
              <ATAKTracks />
            </Map>
          </Stack>
        </Box>
        <RightMissionPlanPanel clickedLand={clickedLand} />
      </Stack>
    );
  } else {
    return <h1>Loading...</h1>;
  }
}
