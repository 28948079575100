import React from "react";
import Paper from "@mui/material/Paper";
import { Button, Stack, Typography } from "@mui/material";
import { useSelector, useDispatch } from "react-redux";
import {
  addMissionAvoidzones,
  addMissionGeofence,
  setMissionGeofence,
} from "../data/slices/featureSlice";
import { app } from "../api/firebase";
import {
  getFirestore,
  doc,
  getDoc,
  updateDoc,
  setDoc,
} from "firebase/firestore";
import { simplifyCoordinates } from "../utilities/features";
import Box from "@mui/material/Box";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import StepContent from "@mui/material/StepContent";
import ContestedLogisticsStepper from "./ContestedLogisticsStepper";
import { setMapMode } from "../data/slices/mapSlice";
import {
  fetchPlan,
  setWaypoints,
  takeSimControl,
} from "../data/slices/planSlice";
import axios from "axios";
import myGeoJSON from "../geodata/demo.json";
import Divider from "@mui/material/Divider";
import MissionSelect from "./MissionSelect";
import InterceptorControl from "./InterceptorControl";
import SectorControl from "./SectorControl";
import TestPanel from "./TestPanel";
import { Play1 } from "./Play1";
import { Play3 } from "./Play3";
import AvoidZonesControl from "./AvoidZonesControl";

const turf = require("@turf/turf");

const db = getFirestore(app);
export default function RightMissionPlanPanel(props) {
  const { clickedLand } = props;
  const dispatch = useDispatch();
  const activeFeature = useSelector((state) => state.feature.activeFeature);
  const currentPlan = useSelector((state) => state.plan.currentPlan);
  const activeAsset = useSelector((state) => state.map.activeAsset);
  const pickup = useSelector((state) => state.plan.pickup);
  const dropoff = useSelector((state) => state.plan.dropoff);
  const geofence = useSelector((state) => state.feature.missionGeofence);
  const avoidZones = useSelector((state) => state.feature.avoidZones);
  const missionType = useSelector((state) => state.plan.missionType);
  const plannerID = useSelector((state) => state.plan.plannerID);
  function bufferGeometryCollection(
    geometryCollection,
    bufferDistance,
    bufferUnit
  ) {
    const bufferedCollection = {
      type: "GeometryCollection",
      geometries: [],
    };

    geometryCollection.geometries.forEach((geometry) => {
      if (geometry.type === "Polygon" || geometry.type === "MultiPolygon") {
        const buffered = turf.buffer(geometry, bufferDistance, {
          units: bufferUnit,
        });
        bufferedCollection.geometries.push(buffered.geometry);
      } else {
        // Add non-polygon geometries to the collection without buffering
        bufferedCollection.geometries.push(geometry);
      }
    });

    return bufferedCollection;
  }
  function filterGeometriesInBoundingBox(boundingBox, geometryCollection) {
    const bboxPolygon = turf.polygon([boundingBox]);

    const filteredPolygonsCoordinates = geometryCollection.geometries.reduce(
      (acc, geometry) => {
        if (geometry.type === "Polygon" || geometry.type === "MultiPolygon") {
          const feature = turf.feature(geometry);
          if (
            turf.booleanContains(bboxPolygon, feature) ||
            turf.booleanOverlap(bboxPolygon, feature)
          ) {
            // If MultiPolygon, handle each Polygon separately
            if (geometry.type === "MultiPolygon") {
              geometry.coordinates.forEach((multiPolygonCoords) => {
                multiPolygonCoords.forEach((polygon) => acc.push(polygon));
              });
            } else {
              // For a single Polygon, push the coordinates directly
              acc.push(geometry.coordinates[0]); // Assuming outer ring only, remove [0] if inner rings are needed
            }
          }
        }
        return acc;
      },
      []
    );

    return filteredPolygonsCoordinates;
  }

  return (
    <Paper
      elevation={3}
      style={{
        width: "20%",
        height: 1000,
        backgroundColor: "black",
        marginTop: 30,
      }}
    >
      <Stack
        direction={"column"}
        alignItems={"center"}
        justifyContent={"center"}
        style={{ marginTop: 30 }}
        spacing={3}
      >
        <Typography variant="h6" style={{ color: "white" }}>
          Mission Plan
        </Typography>
        <MissionSelect />
        {/* <Stack direction="row">
          <Button
            onClick={() => {
              console.log("current plan ", currentPlan);
              const planRef = doc(db, "plans", currentPlan.id);
              setDoc(planRef, {
                name: currentPlan.name,
                center: currentPlan.center,
              });
              dispatch(fetchPlan(currentPlan.id));
              setTimeout(() => {
                window.location.reload(false);
              }, [1000]);
            }}
          >
            Reset Mission
          </Button>
          <Button
            onClick={() => {
              dispatch(
                takeSimControl({ planID: currentPlan.id, plannerID: plannerID })
              );
            }}
          >
            Take Sim Control
          </Button>
        </Stack> */}
        {missionType === "play_1" && <Play1 />}
        {missionType === "contested_logistics" && <ContestedLogisticsStepper />}
        {missionType === "play_3" && <Play3 />}
        {missionType === "interceptor" && <InterceptorControl />}
        {missionType === "sector" && <SectorControl />}
        {missionType === "test" && <TestPanel />}
        {missionType === "avoid" && <AvoidZonesControl />}
      </Stack>
    </Paper>
  );
}
