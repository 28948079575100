import { createSliceWithThunks } from "../../utilities/createSliceWithThunks";
import { app } from "../../api/firebase";
import {
  getFirestore,
  doc,
  getDoc,
  updateDoc,
  query,
  collection,
  where,
  getDocs,
  deleteDoc,
  addDoc,
} from "firebase/firestore";
import axios from "axios";
import { generateString } from "../../api/utilities";

const db = getFirestore(app);
// const SIMURL =
//   "https://simmanager.ngrok.app/7e8a38ab-75bf-4a76-b778-209b3039d21/start_sim";
const SIMURL = "https://simmanager.ngrok.app/";

const GET_UP_SIMS = "https://simmanager.ngrok.app/get_up_sims";

const simSlice = createSliceWithThunks({
  name: "sim",
  initialState: {
    sims: [],
    simID: null,
    havocSims: [],
    simCreateOpen: false,
    requestID: null,
  },
  reducers: (create) => ({
    setSims: create.reducer((state, action) => {
      state.sims = action.payload;
    }),
    setHavocSims: create.reducer((state, action) => {
      state.havocSims = action.payload;
    }),
    setSimCreateOpen: create.reducer((state, action) => {
      state.simCreateOpen = action.payload;
    }),
    removeHavocSim: create.reducer((state, action) => {
      state.havocSims = state.havocSims.filter((sim) => sim !== action.payload);
    }),
    getSims: create.asyncThunk(
      async (obj, thunkAPI) => {
        try {
          const response = await axios.get(GET_UP_SIMS);
          console.log("simulations:", response.data);
          return response.data;
          // setTimeout(async () => {
          //   const id = response.data.id;
          //   const docRef = doc(db, "havoc_sims", id);
          //   const docSnap = await getDoc(docRef);

          //   if (docSnap.exists()) {
          //     let data = docSnap.data();
          //     console.log("Doc data ", data);
          //     return data
          //   } else {
          //     console.log("No such doc!");
          //   }
          // }, 2000)
        } catch (error) {
          console.error("Failed to start simulation:", error);
        }
      },
      {
        fulfilled: (state, action) => {
          // console.log("payload ", action.payload);
          // state.havocSims = action.payload.up_sims;
          state.requestID = action.payload;
        },
      }
    ),

    stopSim: create.asyncThunk(async (obj, thunkAPI) => {
      const simUrl = SIMURL + obj.id + "/stop_sim";
      try {
        const response = await axios.post(simUrl, obj, {
          headers: {
            "Content-Type": "application/json",
          },
        });
        console.log("Simulation stopped successfully:", response.data);
      } catch (error) {
        console.error("Failed to stop simulation:", error);
      }
    }, {}),

    createSim: create.asyncThunk(
      async (obj, thunkAPI) => {
        console.log("here in createSim");
        const dbRef = collection(db, "plans");
        try {
          const docRef = await addDoc(dbRef, {
            name: obj.name,
            center: {
              lat: obj.lat,
              lng: obj.lng,
            },
            sector: obj.sector,
            team: obj.team,
            numberOfBoats: obj.num,
          });

          const data = {
            callsign: obj.team,
            lat: obj.lat,
            lng: obj.lng,
            number: obj.num,
            sector: "testsector1",
            team: obj.team,
            planID: docRef.id,
          };

          try {
            const simUrl = SIMURL + obj.simID + "/start_sim";
            const response = await axios.post(simUrl, data, {
              headers: {
                "Content-Type": "application/json",
              },
            });
            console.log("Simulation started successfully:", response.data);
            return response.data;
          } catch (error) {
            console.error("Failed to start simulation:", error);
          }

          console.log("added ", docRef.id);
        } catch (error) {
          console.log("error adding sim", error);
        }
      },
      {
        fulfilled: (state, action) => {
          console.log("fulfilled ", action.payload);
        },
      }
    ),
    deleteDownSims: create.asyncThunk(async () => {
      const randID = generateString(10);

      const dbRef = collection(db, "sitl_sims");

      // Query for documents where 'up' is true
      const q = query(dbRef, where("up", "==", true));
      const querySnapshot = await getDocs(q);

      // Update each document to set 'request' to randID
      await Promise.all(
        querySnapshot.docs.map((doc) => updateDoc(doc.ref, { request: randID }))
      );

      // Query for documents where 'response' does not equal randID
      const q1 = query(dbRef, where("response", "!=", randID));
      const querySnapshot1 = await getDocs(q1);

      // Corrected: Delete the documents
      await Promise.all(querySnapshot1.docs.map((doc) => deleteDoc(doc.ref)));
    }),

    getUpSims: create.asyncThunk(
      async () => {
        const randID = generateString(10);

        const dbRef = collection(db, "sitl_sims");

        // Query for documents where 'up' is true
        const q = query(dbRef, where("up", "==", true));
        const querySnapshot = await getDocs(q);

        // Update each document to set 'request' to randID
        await Promise.all(
          querySnapshot.docs.map((doc) =>
            updateDoc(doc.ref, { request: randID })
          )
        );

        // Query for documents where 'response' equals randID
        const q1 = query(dbRef, where("response", "==", randID));
        const querySnapshot1 = await getDocs(q1);

        // Extract the data from the query snapshot
        const sims = querySnapshot1.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));

        return { sims: sims, id: randID };
      },
      {
        fulfilled: (state, action) => {
          state.sims = action.payload.sims;
          state.simID = action.payload.id;
        },
      }
    ),
    createSITLRequest: create.asyncThunk(async (obj, api) => {
      console.log("in createSITLRequest", obj);
      const docRef = await addDoc(collection(db, "ardurover_sitl"), {
        sitl_sim_id: obj.id,
        completed: false,
        location: obj.location,
      });
    }),
    requestSimInstance: create.asyncThunk(async (obj, api) => {
      console.log("in here:");
      const simUrl = SIMURL + "request_sim/" + obj.uid;
      console.log("simUrl: ", simUrl);
      const response = await axios.get(simUrl);
      console.log("Sim instance requested: ", response.data);
    }),
    requestStopAllInstances: create.asyncThunk(async (obj, api) => {
      const simUrl = SIMURL + "stop_instances/" + obj.uid;
      const response = await axios.get(simUrl);
      console.log("Sim stp[] requested: ", response.data);
    }),
  }),
});

export const {
  getUpSims,
  setSims,
  deleteDownSims,
  createSITLRequest,
  createSim,
  getSims,
  setSimCreateOpen,
  stopSim,
  removeHavocSim,
  setHavocSims,
  requestSimInstance,
  requestStopAllInstances,
} = simSlice.actions;
export default simSlice.reducer;
