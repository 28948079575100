import * as React from 'react';
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';

export default function TestTemplate(props) {
    const { setName, setLat, setLng, setNum, testTemplates } = props;
    const [selectedTest, setSelectedTest] = React.useState('');

    const handleChange = (event) => {
        setSelectedTest(event.target.value);
        setName(event.target.value.name);
        setLat(event.target.value.lat);
        setLng(event.target.value.lng);
        setNum(event.target.value.num);
        console.log("selected test ", event.target.value)
    };

    return (
        <Box sx={{ minWidth: 400 }}>
            <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">Select Preconfigured Test</InputLabel>
                <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={selectedTest}
                    label="Select Preconfigured Test"
                    onChange={handleChange}
                >
                    {
                        testTemplates.map((test) => (
                            <MenuItem key={test.id} value={test}>{test.name}</MenuItem>
                        ))
                    }

                </Select>
            </FormControl>
        </Box>
    );
}