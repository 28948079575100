const turf = require("@turf/turf");

const characters =
  "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";

function generateString(length) {
  let result = "";
  const charactersLength = characters.length;
  for (let i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }

  return result;
}

function createBoundingBox(point1, point2, boatsInPlan, bufferDistance = 10) {
  console.log("boatsInPlan: " + boatsInPlan);
  // Convert the points to turf points
  const turfPoint1 = turf.point([point1.lng, point1.lat]);
  const turfPoint2 = turf.point([point2.lng, point2.lat]);

  // Create a bounding box that includes both points
  const bbox = turf.bbox(turf.featureCollection([turfPoint1, turfPoint2]));

  // Convert 10 nautical miles to kilometers (1 nautical mile is approximately 1.852 km)
  bufferDistance = bufferDistance * 1.852;

  // Create a buffered bounding box
  // Instead of buffering the bbox and getting a polygon, manually calculate the buffered bbox
  const bufferedBbox = [
    bbox[0] - turf.lengthToDegrees(bufferDistance, "kilometers"),
    bbox[1] - turf.lengthToDegrees(bufferDistance, "kilometers"),
    bbox[2] + turf.lengthToDegrees(bufferDistance, "kilometers"),
    bbox[3] + turf.lengthToDegrees(bufferDistance, "kilometers"),
  ];

  // Return the four corners as [lon, lat] pairs
  const corners = [
    [bufferedBbox[0], bufferedBbox[1]], // bottom-left
    [bufferedBbox[2], bufferedBbox[1]], // bottom-right
    [bufferedBbox[2], bufferedBbox[3]], // top-right
    [bufferedBbox[0], bufferedBbox[3]], // top-left
  ];

  return corners;
}

function createBoundingBoxWithOnePoint(point, bufferDistance = 10) {
  console.log("in createBoundinbBoxWithOnePoint ", point);
  // Convert the point to a turf point
  const turfPoint = turf.point([point.lng, point.lat]);

  // Convert bufferDistance from nautical miles to kilometers (1 nautical mile is approximately 1.852 km)
  bufferDistance = bufferDistance * 1.852;

  // Get the buffered bounding box around the point
  // Instead of creating a bbox and then buffering, we directly create a buffer around the point and then calculate the bbox
  const buffered = turf.buffer(turfPoint, bufferDistance, {
    units: "kilometers",
  });
  const bbox = turf.bbox(buffered);

  // Return the four corners as [lon, lat] pairs
  const corners = [
    [bbox[0], bbox[1]], // bottom-left
    [bbox[2], bbox[1]], // bottom-right
    [bbox[2], bbox[3]], // top-right
    [bbox[0], bbox[3]], // top-left
  ];

  return corners;
}

/**
 * Generates a rectangle polygon around the given points with padding.
 *
 * @param {Array<Array<number>>} points - An array of [longitude, latitude] coordinates.
 * @param {number} [padding=0.1] - Optional padding around the rectangle, in kilometers.
 * @return {Object} A GeoJSON Polygon representing the rectangle around the points with padding.
 */
function createPaddedRectangle(
  points,
  padding = 0.1,
  tolerance = 0.01,
  highQuality = false
) {
  if (!points || points.length === 0) {
    throw new Error("Points array is empty or undefined.");
  }

  // Convert the array of points into a GeoJSON FeatureCollection
  const pointsFeatureCollection = turf.featureCollection(
    points.map((point) => turf.point(point))
  );

  // Calculate the bounding box of the points
  const bbox = turf.bbox(pointsFeatureCollection);

  // Create a rectangle polygon from the bounding box
  let rectangle = turf.bboxPolygon(bbox);

  // Buffer the rectangle to add padding
  // The buffer function takes the unit in kilometers by default
  let paddedRectangle = turf.buffer(rectangle, padding, {
    units: "kilometers",
  });

  let simplifiedRectangle = turf.simplify(paddedRectangle, {
    tolerance: tolerance,
    highQuality: highQuality,
  });
  return simplifiedRectangle;
}

function findMidpoint(coord1, coord2) {
  if (!coord1 || !coord2 || coord1.length !== 2 || coord2.length !== 2) {
    throw new Error(
      "Invalid input: Coordinates must be arrays of two elements."
    );
  }

  const midLongitude = (coord1[0] + coord2[0]) / 2;
  const midLatitude = (coord1[1] + coord2[1]) / 2;

  return [midLongitude, midLatitude];
}
export {
  generateString,
  createBoundingBox,
  createBoundingBoxWithOnePoint,
  createPaddedRectangle,
  findMidpoint,
};
