// hooks/usePlanData.js
import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { useInterval } from "../api/useInterval";
import { doc, onSnapshot, getFirestore } from "firebase/firestore";
import {
  setMissionGeofence,
  setMissionAvoidzones,
} from "../data/slices/featureSlice";
import {
  fetchPlan,
  getBoatsInMissionArea,
  setPickup,
  setDropoff,
  setGeofencePoints,
  setPlan,
  setAvoidZones,
} from "../data/slices/planSlice";
import { app } from "../api/firebase";
import {
  setAssignedSector,
  setAssignedTeam,
} from "../data/slices/sectorsSlice";

import {
  convertObjArrayToCoords,
  revertCoordinates,
} from "../utilities/features";

export const usePlanData = () => {
  const db = getFirestore(app);
  const { planID } = useParams();
  const dispatch = useDispatch();
  const plan = useSelector((state) => state.plan.currentPlan);
  const sectors = useSelector((state) => state.sectors.sectors);

  const subTarget = async (D) => {
    const unsub = onSnapshot(doc(db, "plans", planID), (doc) => {
      console.log("Current plan data: ", doc.data());
      dispatch(setPlan({ id: doc.id, ...doc.data() }));
    });
  };

  useEffect(() => {
    dispatch(fetchPlan(planID));
    // if (planID) {
    //   subTarget(planID);
    // }
  }, [planID, dispatch]);

  // useInterval(() => {
  //   dispatch(fetchPlan(planID));
  // }, 1000);

  useEffect(() => {
    if (plan) {
      // if (plan?.missionGeofence) {
      //   const fence = revertCoordinates(plan.missionGeofence);
      //   // dispatch(setMissionGeofence(fence));
      //   // dispatch(getBoatsInMissionArea(fence?.geometry?.coordinates[0]));
      //   console.log("fence geo ", fence?.geometry?.coordinates[0]);
      // }

      if (plan?.geofencePts) {
        const fence = convertObjArrayToCoords(plan.geofencePts);
        dispatch(setGeofencePoints(fence));
        dispatch(getBoatsInMissionArea(fence));
      }

      if (plan?.avoidZones) {
        let zones = [];
        for (let i in plan?.avoidZones) {
          const zone = plan?.avoidZones[i];
          let coords = [];
          for (let j in zone?.coords) {
            const coord = zone?.coords[j];
            coords.push([coord.lng, coord.lat]);
          }

          zones.push({ id: zone.id, coords: coords });
        }
        dispatch(setAvoidZones(zones));
      }

      if (plan?.sector) {
        const sector = sectors.find((s) => s.id === plan.sector);
        console.log("found sector ", sector);
        dispatch(setAssignedSector(sector));
        // dispatch(setAssignedSector({ name: plan.sector }));
      }

      if (plan?.sector) {
        dispatch(setAssignedTeam(plan.team));
      }

      // let zones = [];
      // for (let z in plan.avoidZones) {
      //   const str = plan.avoidZones[z];
      //   const zone = JSON.parse(str);
      //   zones.push(zone);
      // }

      // dispatch(setMissionAvoidzones(zones));
      dispatch(setPickup(plan.pickupLocation));
      dispatch(setDropoff(plan.dropoffLocation));
    }
  }, [plan, dispatch, sectors]);

  return { plan };
};
