import React, {
  useMemo,
  useState,
  useCallback,
  useRef,
  useEffect,
} from "react";

import Map, { Marker, Source, Layer, NavigationControl } from "react-map-gl";
import "mapbox-gl/dist/mapbox-gl.css";
import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";
import RightTerminalPanel from "../components/RightTerminalPanel";
import { useDispatch, useSelector } from "react-redux";
import { setMapMode } from "../data/slices/mapSlice";
import { createBoundingBoxWithOnePoint } from "../api/utilities";
import {
  setCurrentTerminalAreaPoints,
  setStagingPoint,
  setTerminalPoint,
  setEgressPoint,
  getTerminalAreas,
} from "../data/slices/terminalSlice";
import TerminalAreas from "../components/TerminalAreas";
import { point, booleanPointInPolygon } from "@turf/turf";
export default function TerminalPlan(props) {
  const dispatch = useDispatch();
  const mapMode = useSelector((state) => state.map.mapMode);
  const stagingPoint = useSelector((state) => state.terminal.stagingPoint);
  const terminalPoint = useSelector((state) => state.terminal.terminalPoint);
  const egressPoint = useSelector((state) => state.terminal.egressPoint);
  const terminalAreas = useSelector((state) => state.terminal.terminalAreas);
  const [terminalRoute, setTerminalRoute] = useState(null);
  const mapRef = useRef();
  const currentTerminalArea = useSelector(
    (state) => state.terminal.currentTerminalArea
  );

  useEffect(() => {
    const lineGeoJSON = {
      type: "Feature",
      properties: {},
      geometry: {
        type: "LineString",
        coordinates: [
          [stagingPoint[0], stagingPoint[1]], // From stagingPoint
          [terminalPoint[0], terminalPoint[1]], // To terminalPoint
        ],
      },
    };
    setTerminalRoute(lineGeoJSON);
  }, [stagingPoint, terminalPoint]);
  const [viewport, setViewport] = useState({
    latitude: 41.303164,
    longitude: -72.195684,
    zoom: 13,
  });

  const terminalLayerStyle = {
    id: "polygon",
    type: "fill",
    paint: {
      "fill-color": "#088",
      "fill-opacity": 0.2,
    },
  };

  const handleViewportChange = useCallback(
    (newViewport) => setViewport(newViewport),
    []
  );

  const handleMapClick = useCallback(
    async (e) => {
      console.log("on map click ", mapMode, e.lngLat.lat, e.lngLat.lng);

      let clickedZone = null;
      const terminalIDs = terminalAreas.map((zone) => zone.id);
      const features = mapRef.current.queryRenderedFeatures(e.point, {
        layers: terminalIDs,
      });

      clickedZone = terminalAreas.find(
        (zone) => zone.id === features[0]?.layer?.id
      );

      if (
        clickedZone &&
        mapMode !== "createStagingPoint" &&
        mapMode !== "createTerminalPoint" &&
        mapMode !== "createEgressPoint"
      ) {
        dispatch(setStagingPoint(clickedZone.stagingPoint));
        dispatch(setTerminalPoint(clickedZone.terminalPoint));
        // dispatch(setEgressPoint(clickedZone.egressPoint));
        console.log("clicked on zone ", clickedZone);
      } else if (
        mapMode !== "createStagingPoint" &&
        mapMode !== "createTerminalPoint" &&
        mapMode !== "createEgressPoint"
      ) {
        dispatch(setStagingPoint([]));
        dispatch(setTerminalPoint([]));
      }
      switch (mapMode) {
        case "createTerminalArea":
          console.log("createTerminalArea");
          let boxPts = createBoundingBoxWithOnePoint(
            { lat: e.lngLat.lat, lng: e.lngLat.lng },
            0.5
          );
          console.log("boxPts ", boxPts);
          dispatch(setCurrentTerminalAreaPoints(boxPts));
          break;
        case "createStagingPoint":
          console.log("createStagingPoint");
          dispatch(setStagingPoint([e.lngLat.lng, e.lngLat.lat]));
          break;
        case "createTerminalPoint":
          console.log("createTerminalPoint");
          dispatch(setTerminalPoint([e.lngLat.lng, e.lngLat.lat]));
          break;
        case "createEgressPoint":
          console.log("createEgressPoint");
          dispatch(setEgressPoint([e.lngLat.lng, e.lngLat.lat]));
          break;
        default:
          console.log("default");
          break;
      }
      dispatch(setMapMode("normal"));
    },
    [dispatch, mapMode, terminalAreas]
  );

  useEffect(() => {
    dispatch(getTerminalAreas());
  }, []);
  return (
    <Stack direction={"row"} alignItems={"center"}>
      <Box sx={{ width: "80%", height: "100%" }}>
        <Stack spacing={2} style={{ backgroundColor: "black", marginTop: 30 }}>
          <Map
            {...viewport}
            onViewportChange={(nextViewport) => setViewport(nextViewport)}
            mapLib={import("mapbox-gl")}
            mapboxAccessToken="pk.eyJ1IjoicGx3aW4yMDA3IiwiYSI6ImNsbjBqamg5aTFkY3oycW83dDg2c2dja20ifQ.Fvas3o3mJGgRlb9z3eFfaA"
            onMove={(evt) => setViewport(evt.viewState)}
            style={{ width: "100%", height: 1000 }}
            mapStyle="mapbox://styles/mapbox/satellite-v9"
            minPitch={0}
            maxPitch={0}
            onClick={handleMapClick}
            ref={mapRef}
          >
            <NavigationControl />
            <TerminalAreas />
            {stagingPoint.length > 0 && (
              <Marker
                latitude={stagingPoint[1]}
                longitude={stagingPoint[0]}
                offsetLeft={-15} // Adjust these values based on the size of your square
                offsetTop={-15}
              >
                <div
                  style={{
                    width: "15px", // Square size
                    height: "15px", // Square size
                    backgroundColor: "green",
                    borderRadius: "0%", // This makes it a square
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  {/* You can insert any content or just leave it empty */}
                </div>
              </Marker>
            )}
            {terminalPoint.length > 0 && (
              <Marker
                latitude={terminalPoint[1]}
                longitude={terminalPoint[0]}
                offsetLeft={-15} // Center the triangle horizontally
                offsetTop={-30} // Adjust based on the triangle's height
              >
                <div
                  style={{
                    width: "0",
                    height: "0",
                    borderLeft: "7px solid transparent", // Half the width of the triangle
                    borderRight: "7px solid transparent", // Half the width of the triangle
                    borderBottom: "15px solid green", // Height of the triangle
                  }}
                >
                  {/* This div is styled to look like a triangle */}
                </div>
              </Marker>
            )}

            {stagingPoint.length > 0 && terminalPoint.length > 0 && (
              <Source id="line" type="geojson" data={terminalRoute}>
                <Layer
                  id="lineLayer"
                  type="line"
                  layout={{
                    "line-join": "round",
                    "line-cap": "round",
                  }}
                  paint={{
                    "line-color": "green", // Line color
                    "line-width": 3, // Line width
                  }}
                />
              </Source>
            )}
            {egressPoint.length > 0 && (
              <Marker
                latitude={egressPoint[1]}
                longitude={egressPoint[0]}
                color={"orange"}
                draggable
              ></Marker>
            )}
          </Map>
        </Stack>
      </Box>
      <RightTerminalPanel />
    </Stack>
  );
}
