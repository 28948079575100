import { getFirestore, collection, addDoc, getDocs } from "firebase/firestore";

import { app } from "../../api/firebase";
import { createSliceWithThunks } from "../../utilities/createSliceWithThunks";
const db = getFirestore(app);

const terminalSlice = createSliceWithThunks({
  name: "terminal",
  initialState: {
    terminalAreas: [],
    stagingPoint: [],
    terminalPoint: [],
    egressPoint: [],
    currentTerminalAreaPoints: [],
    currentTerminalArea: null,
  },
  reducers: (create) => ({
    setTerminalAreas: create.reducer((state, action) => {
      state.terminalAreas = action.payload;
    }),
    setCurrentTerminalAreaPoints: create.reducer((state, action) => {
      state.currentTerminalAreaPoints = action.payload;
    }),
    setStagingPoint: create.reducer((state, action) => {
      state.stagingPoint = action.payload;
    }),
    setTerminalPoint: create.reducer((state, action) => {
      state.terminalPoint = action.payload;
    }),
    setEgressPoint: create.reducer((state, action) => {
      state.egressPoint = action.payload;
    }),
    setCurrentTerminalArea: create.reducer((state, action) => {
      state.currentTerminalArea = action.payload;
    }),
    saveTerminalArea: create.asyncThunk(
      async (obj, thunkAPI) => {
        console.log("save terminal area to db", obj);
        try {
          const docRef = await addDoc(collection(db, "terminalAreas"), obj);
          console.log("Document written with ID: ", docRef.id);
        } catch (e) {
          console.log("error ", e);
        }
        setTimeout(window.location.reload(), 3000);
        return obj;
      },
      {
        // fulfilled: (state, action) => {
        //   state.terminalAreas.push(action.payload);
        // },
      }
    ),
    getTerminalAreas: create.asyncThunk(
      async (obj, thunkAPI) => {
        console.log("getting terminal areas");
        const terminalAreas = [];
        const querySnapshot = await getDocs(collection(db, "terminalAreas"));
        querySnapshot.forEach((doc) => {
          terminalAreas.push({ id: doc.id, ...doc.data() });
        });
        return terminalAreas;
      },
      {
        fulfilled: (state, action) => {
          console.log("getting terminal areas ", action.payload);

          state.terminalAreas = action.payload;
        },
      }
    ),
  }),
});

export const {
  setTerminalAreas,
  setCurrentTerminalAreaPoints,
  setStagingPoint,
  setTerminalPoint,
  setEgressPoint,
  setCurrentTerminalArea,
  saveTerminalArea,
  getTerminalAreas,
} = terminalSlice.actions;

export default terminalSlice.reducer;
