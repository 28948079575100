import { createSlice } from "@reduxjs/toolkit";
import { createSliceWithThunks } from "../../utilities/createSliceWithThunks";
import { simplifyCoordinates } from "../../utilities/features";
import { getFirestore, doc, updateDoc, arrayUnion } from "firebase/firestore";
import { app } from "../../api/firebase";

const db = getFirestore(app);
const featureSlice = createSliceWithThunks({
  name: "feature",
  initialState: {
    activeFeature: null,
    missionGeofence: null,
    avoidZones: [],
  },
  reducers: (create) => ({
    setActiveFeature: create.reducer((state, action) => {
      state.activeFeature = action.payload;
    }),
    setMissionGeofence: create.reducer((state, action) => {
      state.missionGeofence = action.payload;
    }),
    addMissionGeofence: create.asyncThunk(
      async (payload, thunkApi) => {
        const { id, geofence } = payload;
        console.log("in add ", id, geofence);
        const copy = simplifyCoordinates(geofence);
        const planRef = doc(db, "plans", id);
        const updateRes = await updateDoc(planRef, {
          missionGeofence: copy,
        });
        return geofence;
      },
      {
        fulfilled: (state, action) => {
          console.log("fulfilled ", action.payload);
          state.missionGeofence = action.payload;
        },
      }
    ),
    addMissionAvoidzones: create.asyncThunk(
      async (payload, thinkApi) => {
        console.log("payload str ", payload);
        const zoneStr = JSON.stringify(payload.avoidZone);
        const planRef = doc(db, "plans", payload.id);
        const updateRes = await updateDoc(planRef, {
          avoidZones: arrayUnion(zoneStr),
        });
        return payload.avoidZone;
      },
      {
        fulfilled: (state, action) => {
          console.log("fulfilled ", action.payload);
          state.avoidZones.push(action.payload);
        },
      }
    ),
    setMissionAvoidzones: create.reducer((state, action) => {
      state.avoidZones = action.payload;
    }),
  }),
});

export const {
  setActiveFeature,
  addMissionGeofence,
  setMissionGeofence,
  addMissionAvoidzones,
  setMissionAvoidzones,
} = featureSlice.actions;
export default featureSlice.reducer;
