// const URL = "http://localhost:8080";

// async function getMissionData(missionID) {
//   const response = await fetch(`${URL}/mission/${missionID}`);
//   return response.json();
// }

// export { getMissionData };
import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { url } from "./url";
export const boatApi = createApi({
  reducerPath: "boatApi",
  baseQuery: fetchBaseQuery({
    baseUrl: url,
  }),
  endpoints: (builder) => ({
    getBoatByID: builder.query({
      query: (id) => `boat/${id}`,
    }),
  }),
});

export const { useGetBoatByID } = boatApi;
