import React, {
  useMemo,
  useState,
  useCallback,
  useRef,
  useEffect,
} from "react";
import { useDispatch, useSelector } from "react-redux";

import Map, { Marker, Source, Layer, NavigationControl } from "react-map-gl";
import "mapbox-gl/dist/mapbox-gl.css";
import { createPaddedRectangle } from "../api/utilities";

const terminalLayerStyle = {
  type: "fill",
  paint: {
    "fill-color": "purple",
    "fill-opacity": 0.2,
  },
};

export default function TerminalAreas(props) {
  const mapMode = useSelector((state) => state.map.mapMode);
  const terminalAreas = useSelector((state) => state.terminal.terminalAreas);

  return (
    <div>
      {terminalAreas.map((area, index) => {
        let geojson = createPaddedRectangle([
          area.stagingPoint,
          area.terminalPoint,
          area.egressPoint,
        ]);
        return (
          <Source id={area.id} key={index} type="geojson" data={geojson}>
            <Layer id={area.id} {...terminalLayerStyle} />
          </Source>
        );
      })}
    </div>
  );
}
