import * as React from "react";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import Box from "@mui/material/Box";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import { useDispatch, useSelector } from "react-redux";
import { setMapMode } from "../data/slices/mapSlice";
import { generateString } from "../api/utilities";
import {
  sendMissionGeofence,
  sendMissionAvoidzones,
  sendGotoPt,
} from "../api/plan";
import { setGeofencePoints } from "../data/slices/planSlice";
import { Typography } from "@mui/material";
// Create a theme instance.
const darkTheme = createTheme({
  palette: {
    mode: "dark",
  },
});

export default function GeofenceControl(props) {
  const dispatch = useDispatch();
  const geofencePts = useSelector((state) => state.plan.geofence);
  const assignedSector = useSelector((state) => state.sectors.assignedSector);
  const { currentPlan, boatsInPlan, pickup, dropoff } = useSelector(
    (state) => state.plan
  );
  const [mission, setMission] = React.useState("contested_logistics");

  const onDrawGeofence = async () => {
    console.log("Create Geofence");
    dispatch(setGeofencePoints([]));
    dispatch(setMapMode("create_geofence"));
  };

  const onSetGeofence = async () => {
    let coords = [];
    let boats = [];
    for (let c of boatsInPlan) {
      let boatSpl = c.split("/");
      const boatID = `${boatSpl[0]}/${boatSpl[1]}/${boatSpl[2]}/${boatSpl[3]}`;
      boats.push(boatID);
    }
    for (let c of geofencePts) {
      coords.push({ x: c[0], y: c[1], z: 0 });
    }
    coords.push(coords[0]);
    const geofence = {
      header: {
        stamp: {
          sec: Math.floor(Date.now() / 1000), // seconds since epoch
          nanosec: (Date.now() % 1000) * 1000000, // nanoseconds
        },
        frame_id: "geofence",
      },
      polygon: {
        points: coords,
      },
    };
    const payload1 = {
      id: generateString(5),
      boats: boats,
      geofence: geofence,
    };
    sendMissionGeofence(payload1);
  };

  return (
    <ThemeProvider theme={darkTheme}>
      <Box sx={{ minWidth: 120, color: "white" }}>
        <Stack spacing={2}>
          <Typography>Assigned Comms Sector: {assignedSector?.name}</Typography>

          {/* <Button variant="contained" onClick={onSetGeofence}>
            Use Sector
          </Button> */}
        </Stack>
      </Box>
    </ThemeProvider>
  );
}
