import { createSlice } from "@reduxjs/toolkit";

const mapSlice = createSlice({
  name: "map",
  initialState: {
    mapMode: "normal",
    activeAsset: null,
    activeBoat: null,
  },
  reducers: {
    setMapMode: (state, action) => {
      state.mapMode = action.payload;
    },
    setActiveAsset: (state, action) => {
      state.activeAsset = action.payload;
    },
    setActiveBoat: (state, action) => {
      state.activeBoat = action.payload;
    },
  },
});

export const { setMapMode, setActiveAsset, setActiveBoat } = mapSlice.actions;
export default mapSlice.reducer;
