// const URL = "http://localhost:8080";

// async function getMissionData(missionID) {
//   const response = await fetch(`${URL}/mission/${missionID}`);
//   return response.json();
// }

// export { getMissionData };
import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { url } from "./url";
export const missionApi = createApi({
  reducerPath: "missionApi",
  baseQuery: fetchBaseQuery({
    baseUrl: url,
  }),
  endpoints: (builder) => ({
    getMissionByID: builder.query({
      query: (id) => `mission/${id}`,
    }),
  }),
});

export const { useGetMissionByID } = missionApi;
