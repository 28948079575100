import React, { useState } from "react";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { Box, Button, Stack, Typography } from "@mui/material";
import { setMapMode } from "../data/slices/mapSlice";
import { useDispatch, useSelector } from "react-redux";
import { sendPlay, sendRTL, sendDisarm } from "../api/plan";
import { sendMissionGeofence } from "../api/plan";
import SpeedSlider from "./SpeedSlider";
import { getFirestore, doc, updateDoc } from "firebase/firestore";
import { app } from "../api/firebase";
import {
  createBoundingBoxWithOnePoint,
  generateString,
} from "../api/utilities";
import {
  clearSearchAreaPts,
  fetchPlan,
  getBoatsInMissionArea,
  sendSensorTrack,
  setTestTrackPt,
} from "../data/slices/planSlice";
import TextField from "@mui/material/TextField";
import { config } from "localforage";

const darkTheme = createTheme({
  palette: {
    mode: "dark",
  },
});

const degreesToRads = (deg) => (deg * Math.PI) / 180.0;

export function Play3() {
  const db = getFirestore(app);
  const dispatch = useDispatch();
  const { currentPlan, boatsInPlan, geofence, searchAreaPts, testTrackPt } =
    useSelector((state) => state.plan);
  const assignedSector = useSelector((state) => state.sectors.assignedSector);
  const assignedTeam = useSelector((state) => state.sectors.assignedTeam);
  const activeAsset = useSelector((state) => state.map.activeAsset);
  const geofencePts = useSelector((state) => state.plan.geofence);
  const [task1Config, setTask1Config] = useState(0);
  const [task1d0_m, setTask1D0_m] = useState(1000.0);
  const [task1d1_m, setTask1D1_m] = useState(500.0);
  const [task1a_rad, setTask1A_rad] = useState(180.0);
  const [task1b_rad, setTask1B_rad] = useState(45.0);
  const [task1speed, setTask1Speed] = useState(2.0);
  const [task2d0_m, setTask2D0_m] = useState(0.0);
  const [task2d1_m, setTask2D1_m] = useState(0.0);
  const [task2a_rad, setTask2A_rad] = useState(0.0);
  const [task2b_rad, setTask2B_rad] = useState(0.0);
  const [task2speed, setTask2Speed] = useState(5.0);
  const [tgtLat, setTgtLat] = useState(0.0);
  const [tgtLon, setTgtLon] = useState(0.0);

  const onSendPlay3 = async () => {
    const task1 = {
      task_id: 1,
      geo_pose: [
        {
          position: {
            longitude: currentPlan.testTrackPt.coordinates[0],
            latitude: currentPlan.testTrackPt.coordinates[1],
            altitude: 0.0,
          },
          orientation: {
            x: 0.0,
            y: 0.0,
            z: 0.0,
            w: 1.0,
          },
        },
      ],
      area: [],
      auth_proceed: true,
      auth_continue: true,
      reference_track_uuid: currentPlan?.testTrackPt?.id
        ? currentPlan?.testTrackPt?.id
        : "",
      configuration: parseInt(task1Config),
      d0_m: parseFloat(task1d0_m),
      d1_m: parseFloat(task1d1_m),
      a_rad: degreesToRads(task1a_rad),
      b_rad: degreesToRads(task1b_rad),
      target_speed_mps: parseFloat(task1speed * 0.5144),
      respect_avoids: true,
      min_force: 1,
      goal_reach_tolerance_m: 10.0,
    };
    const task2 = {
      task_id: 2,
      geo_pose: [
        {
          position: {
            longitude: currentPlan.testTrackPt.coordinates[0],
            latitude: currentPlan.testTrackPt.coordinates[1],
            altitude: 0.0,
          },
          orientation: {
            x: 0.0,
            y: 0.0,
            z: 0.0,
            w: 1.0,
          },
        },
      ],
      area: [],
      auth_proceed: true,
      auth_continue: true,
      reference_track_uuid: "",
      configuration: 0,
      d0_m: parseFloat(task2d0_m),
      d1_m: parseFloat(task2d1_m),
      a_rad: degreesToRads(task2a_rad),
      b_rad: degreesToRads(task2b_rad),
      target_speed_mps: parseFloat(task2speed * 0.5144),
      respect_avoids: true,
      min_force: 1,
      goal_reach_tolerance_m: 10.0,
    };

    const play = {
      header: {
        stamp: {
          sec: Math.floor(Date.now() / 1000), // seconds since epoch
          nanosec: (Date.now() % 1000) * 1000000, // nanoseconds
        },
        frame_id: "play4",
      },
      playbook_version: 1,
      play_id: 3,
      task_parameters: [task1, task2],
    };
    let data = {
      team: assignedTeam,
      sector: assignedSector.name,
      play: play,
    };
    console.log("data ", data);
    sendPlay(data);
  };
  const onSendSensorTrack = async () => {
    dispatch(
      sendSensorTrack({
        id: currentPlan.testTrackPt.id,
        lat: currentPlan.testTrackPt.coordinates[1],
        lng: currentPlan.testTrackPt.coordinates[0],
        sector: assignedSector.name,
        team: assignedTeam,
      })
    );
  };

  const onCreateTestTrack = async () => {
    dispatch(setMapMode("addTestTrackPoint"));
  };

  const updateTestTarget = async () => {
    const planRef = doc(db, "plans", currentPlan.id);

    await updateDoc(planRef, {
      testTrackPt: {
        id: generateString(10),
        coordinates: testTrackPt,
      },
    });

    dispatch(fetchPlan(currentPlan.id));
  };

  return (
    <ThemeProvider theme={darkTheme}>
      <Box sx={{ maxWidth: 600, maxHeight: 800 }}>
        <Stack direction="Column" spacing={2} justifyContent="center">
          <Button onClick={onCreateTestTrack}>Create Target Track</Button>
          <Button onClick={updateTestTarget}>Save Target Track</Button>
          <Button onClick={onSendSensorTrack}>Send Target Track</Button>
          <TextField
            id="outlined-basic"
            label="Lat"
            variant="outlined"
            type={"number"}
            InputLabelProps={{
              shrink: true,
            }}
            value={tgtLat}
            onChange={(e) => setTgtLat(e.target.value)}
          />
          <TextField
            id="outlined-basic"
            label="Lon"
            variant="outlined"
            type={"number"}
            InputLabelProps={{
              shrink: true,
            }}
            value={tgtLon}
            onChange={(e) => setTgtLon(e.target.value)}
          />
          <Button onClick={() => dispatch(setTestTrackPt([tgtLon, tgtLat]))}>
            Move Tgt (Reclick to update)
          </Button>
          <TextField
            id="outlined-basic"
            label="Configuration"
            variant="outlined"
            type={"number"}
            InputLabelProps={{
              shrink: true,
            }}
            value={task1Config}
            onChange={(e) => setTask1Config(e.target.value)}
          />
          <TextField
            id="outlined-basic"
            label="d0_m"
            variant="outlined"
            type={"number"}
            InputLabelProps={{
              shrink: true,
            }}
            value={task1d0_m}
            onChange={(e) => setTask1D0_m(e.target.value)}
          />
          <TextField
            id="outlined-basic"
            label="d1_m"
            variant="outlined"
            type={"number"}
            InputLabelProps={{
              shrink: true,
            }}
            value={task1d1_m}
            onChange={(e) => setTask1D1_m(e.target.value)}
          />
          <TextField
            id="outlined-basic"
            label="a_rad"
            variant="outlined"
            type={"number"}
            InputLabelProps={{
              shrink: true,
            }}
            value={task1a_rad}
            onChange={(e) => setTask1A_rad(e.target.value)}
          />
          <TextField
            id="outlined-basic"
            label="b_rad"
            variant="outlined"
            type={"number"}
            InputLabelProps={{
              shrink: true,
            }}
            value={task1b_rad}
            onChange={(e) => setTask1B_rad(e.target.value)}
          />
          <TextField
            id="outlined-basic"
            label="Target Speed (knots)"
            variant="outlined"
            type={"number"}
            InputLabelProps={{
              shrink: true,
            }}
            value={task1speed}
            onChange={(e) => setTask1Speed(e.target.value)}
          />
          <Button onClick={onSendPlay3}>Send Play 3</Button>
        </Stack>
      </Box>
    </ThemeProvider>
  );
}
