import React, { useState, useCallback, useRef, useEffect } from "react";
import { useParams } from "react-router-dom";
import { usePlanData } from "../hooks/usePlanData";
import { app } from "../api/firebase";
import { getFirestore, doc, getDoc } from "firebase/firestore";
import { useDispatch, useSelector } from "react-redux";
import { setAssignedSector } from "../data/slices/sectorsSlice";
import axios from "axios";
import { BoatsWS } from "../components/BoatsWS";
export default function MissionPlanNew(props) {
  const db = getFirestore(app);
  const dispatch = useDispatch();
  const { planID } = useParams();
  const { plan } = usePlanData();
  const assignedSector = useSelector((state) => state.sectors.assignedSector);
  const [boatsInTeamSector, setBoatsInTeamSector] = useState([]);
  const [initComplete, setInitCoplete] = useState(false);
  const [viewport, setViewport] = useState({
    latitude: 37.7577,
    longitude: -122.4376,
    zoom: 12,
    bearing: 0,
  });
  const [tempJWT, setTempJWT] = useState("");

  async function getSector(id) {
    const docRef = doc(db, "sectors", id);
    const docSnap = await getDoc(docRef);
    if (docSnap.exists()) {
      console.log("Document data:", docSnap.data());
      dispatch(setAssignedSector(docSnap.data()));
    } else {
      // doc.data() will be undefined in this case
      console.log("No such document!");
    }
  }

  useEffect(() => {
    if (!initComplete && plan) {
      setViewport({
        latitude: plan.center.lat,
        longitude: plan.center.lng,
        zoom: 12,
        bearing: 0,
      });
      //   let area = createBoundingBoxWithOnePoint({
      //     lat: plan.center.lat,
      //     lng: plan.center.lng,
      //   });
      //   dispatch(setGeofencePoints(area));
      setInitCoplete(true);

      getSector(plan.sector);
    }
  }, [initComplete, plan]);

  async function getBoats() {
    console.log("tempjwt ", tempJWT);
    const boats = [];
    const url = `https://dev.havocai.net/api/v0/boat?team=${plan.team}&sector=${assignedSector.name}`;
    console.log(url);
    let res = await axios.get(url, {
      headers: {
        Authorization: `Bearer ${tempJWT}`,
      },
    });
    const localBoatsInTeamSector = res?.data?.values;
    for (let boat of localBoatsInTeamSector) {
      boats.push(boat.name);
      console.log("boat ", boat.name);
    }
    setBoatsInTeamSector(boats);
  }

  useEffect(() => {
    if (plan && assignedSector && tempJWT !== "") {
      getBoats();
    }
  }, [assignedSector, plan, tempJWT]);

  async function getSub() {
    const url = "https://dev.havocai.net/api/v0/login";
    let res = await axios.post(url, {
      username: "admin",
      password: "havocaiisdabomb",
    });
    console.log("login res ", res.data.token);
    setTempJWT(res.data.token);
  }

  useEffect(() => {
    getSub();
  }, []);



  return (
    <div>
      <h1>Mission Plan New</h1>
      <p>Plan ID: {planID}</p>
      {boatsInTeamSector.length > 0 && tempJWT !== "" && (
        <BoatsWS boatIDs={boatsInTeamSector} token={tempJWT} />
      )}
    </div>
  );
}
