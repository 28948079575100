import * as React from "react";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import Divider from "@mui/material/Divider";
import { Typography } from "@mui/material";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import { useSelector, useDispatch } from "react-redux";

import {
  createSim,
  removeHavocSim,
  setSimCreateOpen,
} from "../data/slices/simSlice";
import { generateString } from "../api/utilities";
import TestTemplate from "./TestTemplate";
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 600,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  pt: 2,
  px: 4,
  pb: 3,
};

// 41.278375, -72.154475
const testTemplates = [
  {
    id: 1,
    name: "JohnLangTest",
    lat: "41.278375",
    lng: "-72.154475",
    num: "3",
  },
  {
    id: 2,
    name: "AlpenaTest",
    lat: "45.049208",
    lng: "-83.428730",
    num: "5",
  },
  {
    id: 3,
    name: "FulcrumTest",
    lat: "41.842613",
    lng: "-71.375435",
    num: "3",
  },
];

function CreateSimModal(props) {
  const { open, setOpen, activeSimID } = props;
  const [name, setName] = React.useState("BETest");
  const [lat, setLat] = React.useState("45.011169");
  const [lng, setLng] = React.useState("-83.277771");
  const [num, setNum] = React.useState("9");
  const [team, setTeam] = React.useState(generateString(5));

  const dispatch = useDispatch();
  const havocSims = useSelector((state) => state.sim.havocSims);
  const handleClose = () => {
    dispatch(setSimCreateOpen(false));
  };

  const onCreate = async () => {
    // Parse numeric values from strings, defaulting to 0 if parsing fails
    const parsedLat = parseFloat(lat) || 0;
    const parsedLng = parseFloat(lng) || 0;
    const parsedNum = parseInt(num, 10) || 0;

    const data = {
      name: name,
      lat: parsedLat,
      lng: parsedLng,
      num: parsedNum,
      team: team,
      sector: "KTQ2an6C3K6ophdNsqTt",
      simID: activeSimID,
    };

    dispatch(createSim(data));
    dispatch(setSimCreateOpen(false));
    dispatch(removeHavocSim(activeSimID));
  };

  React.useEffect(() => {
    console.log("havoc sims ", havocSims);
  }, [havocSims]);

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="child-modal-title"
      aria-describedby="child-modal-description"
    >
      <Box sx={{ ...style, width: 600 }}>
        <Stack
          spacing={2}
          direction="column"
          justifyContent="center"
          alignItems="center"
          style={{ marginTop: 50 }}
        >
          <Typography>Selected Sim ID: {activeSimID}</Typography>
          <TestTemplate
            testTemplates={testTemplates}
            setName={setName}
            setLat={setLat}
            setLng={setLng}
            setNum={setNum}
          />
          <TextField
            id="standard-basic"
            label="Sim Name"
            variant="standard"
            value={name}
            onChange={(e) => setName(e.target.value)}
          />
          <TextField
            id="standard-basic"
            label="Center Latitude"
            variant="standard"
            value={lat}
            onChange={(e) => setLat(e.target.value)}
          />
          <TextField
            id="standard-basic"
            label="Center Longitude"
            variant="standard"
            value={lng}
            onChange={(e) => setLng(e.target.value)}
          />
          <TextField
            id="standard-basic"
            label="Number of Boats"
            variant="standard"
            value={num}
            onChange={(e) => setNum(e.target.value)}
          />
          <TextField
            id="standard-basic"
            label="Team Name"
            variant="standard"
            value={team}
            onChange={(e) => setTeam(e.target.value)}
          />

          <Button variant="contained" onClick={() => onCreate()}>
            Create
          </Button>
        </Stack>
      </Box>
    </Modal>
  );
}

export default CreateSimModal;
