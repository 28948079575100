import * as React from "react";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import Box from "@mui/material/Box";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { useSelector, useDispatch } from "react-redux";
import { setMissionType } from "../data/slices/planSlice";
// Create a theme instance.
const darkTheme = createTheme({
  palette: {
    mode: "dark",
  },
});

export default function MissionSelect() {
  const dispatch = useDispatch();
  const [mission, setMission] = React.useState("interceptor");
  const missionType = useSelector((state) => state.plan.missionType);

  const handleChange = (event) => {
    setMission(event.target.value);
    dispatch(setMissionType(event.target.value));
  };

  return (
    <ThemeProvider theme={darkTheme}>
      <Box sx={{ minWidth: 150, color: "white" }}>
        <FormControl fullWidth>
          <InputLabel id="demo-simple-select-label">
            Select Control Type
          </InputLabel>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={missionType}
            label="Select A Mission"
            onChange={handleChange}
          >
            <MenuItem value={"play_1"}>Play 1</MenuItem>
            <MenuItem value={"contested_logistics"}>Play 2</MenuItem>
            <MenuItem value={"play_3"}>Play 3</MenuItem>
            <MenuItem value={"interceptor"}>Play 4</MenuItem>
            <MenuItem value={"sector"}>Manage Sectors</MenuItem>
            <MenuItem value={"avoid"}>Avoidzones</MenuItem>
            <MenuItem value={"test"}>Manage Test</MenuItem>
          </Select>
        </FormControl>
      </Box>
    </ThemeProvider>
  );
}
