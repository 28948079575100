import { createSlice } from "@reduxjs/toolkit";
import { createSliceWithThunks } from "../../utilities/createSliceWithThunks";

const boatsSlice = createSliceWithThunks({
  name: "boats",
  initialState: {
    currentBoats: [],
  },
  reducers: (create) => ({
    setCurrentBoats: create.reducer((state, action) => {
      state.currentBoats = action.payload;
    }),
    addOrUpdateBoat: create.reducer((state, action) => {
      const index = state.currentBoats.findIndex(
        (boat) => boat.id === action.payload.id
      );
      if (index !== -1) {
        state.currentBoats[index] = action.payload; // Update existing boat
      } else {
        state.currentBoats.push(action.payload); // Add new boat
      }
    }),
    removeBoat: create.reducer((state, action) => {
      state.currentBoats = state.currentBoats.filter(
        (boat) => boat.id !== action.payload
      );
    }),
  }),
});

// Selectors
export const selectAllBoats = (state) => state.boats.currentBoats;
export const selectBoatById = (state, boatId) =>
  state.boats.currentBoats.find((boat) => boat.id === boatId);

// Export actions and reducer
export const { setCurrentBoats, addOrUpdateBoat, removeBoat } =
  boatsSlice.actions;
export default boatsSlice.reducer;
