/**
 * ProtectedRoute Component
 *
 * This component is a higher-order component (HOC) designed to wrap around any part of the
 * application that requires user authentication. It utilizes Firebase Authentication to
 * determine if a user is currently authenticated. If the user is not authenticated, it redirects
 * them to the login page. Otherwise, it renders the child components, effectively protecting
 * the route from unauthorized access.
 *
 * Dependencies:
 * - React for functional component structure and lifecycle effects.
 * - Redux for global state management, specifically for managing user authentication state.
 * - Firebase Auth for handling user authentication status checks.
 * - React Router DOM for programmatic navigation and conditional routing based on authentication status.
 *
 * How it works:
 * - On component mount, an effect hook establishes a subscription to Firebase Authentication's
 *   state change events, updating the global Redux store with the user's authentication status.
 * - Utilizes Redux to manage and access the application's authentication state, including whether
 *   the authentication initialization process has completed and whether the user is currently logged in.
 * - Depending on the authentication state, it either renders a loading indicator, redirects
 *   the user to the login page, or renders the child components that are passed to it.
 *
 * Usage:
 * Wrap any component or route that should be protected by authentication with this component.
 * Only authenticated users will be able to access the wrapped components or routes.
 *
 * Example:
 * ```jsx
 * <ProtectedRoute>
 *   <YourComponent />
 * </ProtectedRoute>
 * ```
 *
 * Notes:
 * Ensure that Firebase is correctly initialized within your application and that the Redux
 * store is properly configured to manage the authentication state. Consider customizing the
 * loading indicator to better match your application's design and user experience expectations.
 */

import React, { useEffect } from "react";

import { useDispatch, useSelector } from "react-redux";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import { app } from "../api/firebase";
import { setUser, setAuthInitiated } from "../data/slices/userSlice";
import { useNavigate, Navigate, useLocation } from "react-router-dom";
const ProtectedRoute = ({ children }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const auth = getAuth(app);
  const location = useLocation();
  // Consider adding an 'isAuthInitialized' state to your Redux store
  const user = useSelector((state) => state.user.user);
  const isAuthInitialized = useSelector(
    (state) => state.user.isAuthInitialized
  );

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (currentUser) => {
      if (currentUser) {
        const userData = {
          uid: currentUser.uid,
          email: currentUser.email,
          displayName: currentUser.displayName,
          // Add any other fields you need that are serializable
        };
        console.log("user logged in ", currentUser);

        dispatch(setUser(userData));
        dispatch(setAuthInitiated(true)); // Update the state to indicate auth initialization is complete
      } else {
        console.log("user logged out");
        dispatch(setUser(null));
        dispatch(setAuthInitiated(true)); // Update the state to indicate auth initialization is complete
      }
    });
    return unsubscribe; // Cleanup subscription on unmount
  }, [dispatch]);

  if (!isAuthInitialized) {
    // Optionally, render a loading indicator while checking auth state
    return <div>Loading...</div>;
  }

  if (!user) {
    // User not logged in, redirect to login page
    return <Navigate to="/login" state={{ from: location }} replace />;
  }

  return children; // User is logged in, render the protected component
};

export default ProtectedRoute;
