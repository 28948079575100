import { combineReducers, configureStore } from "@reduxjs/toolkit";
import { missionApi } from "../api/mission";

import { setupListeners } from "@reduxjs/toolkit/query";
import { boatApi } from "../api/boat";
import planReducer from "./slices/planSlice";
import featureReducer from "./slices/featureSlice";
import mapReducer from "./slices/mapSlice";
import simReducer from "./slices/simSlice";
import userReducer from "./slices/userSlice";
import sectorsReducer from "./slices/sectorsSlice";
import terminalReducer from "./slices/terminalSlice";
import boatsReducer from "./slices/boatsSlice";
export const store = configureStore({
  reducer: {
    [missionApi.reducerPath]: missionApi.reducer,
    [boatApi.reducerPath]: boatApi.reducer,
    plan: planReducer,
    feature: featureReducer,
    map: mapReducer,
    sim: simReducer,
    user: userReducer,
    sectors: sectorsReducer,
    terminal: terminalReducer,
    boats: boatsReducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware()
      .concat(missionApi.middleware)
      .concat(boatApi.middleware),
});

setupListeners(store.dispatch);
