import React, { useState, useCallback, useEffect, useRef } from "react";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import {
  setCurrentBoats,
  addOrUpdateBoat,
  selectAllBoats,
} from "../data/slices/boatsSlice";
export const BoatsWS = (props) => {
  const { boatIDs, token } = props;
  const boats = useSelector((state) => state.boats.currentBoats);
  const [messages, setMessages] = useState(new Map());
  const [connectionStatus, setConnectionStatus] = useState("Connecting...");
  const dispatch = useDispatch();
  async function putBoats() {
    const url = "https://dev.havocai.net/api/v0/subscription";
    axios.put(
      url,
      {
        boats: boatIDs,
        updateInterval: 3000,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
  }

  useEffect(() => {
    console.log("boats ", boats);
  }, [boats]);
  useEffect(() => {
    if (boatIDs.length > 0 && token !== "") {
      putBoats();
      if (token !== "") {
        const socket = new WebSocket(
          "wss://dev.havocai.net/api/v0/subscription/websocket?token=" + token
        );

        socket.onopen = () => {
          setConnectionStatus("Connecteda");
        };

        socket.onmessage = (event) => {
          const message = JSON.parse(event.data);
          dispatch(addOrUpdateBoat(message));
          // console.log("message ", message);
        };

        socket.onclose = () => {
          setConnectionStatus("Disconnected");
        };

        socket.onerror = (error) => {
          console.error("WebSocket error:", error);
          setConnectionStatus("Error");
        };

        return () => {
          socket.close();
        };
      }
    }
  }, [boatIDs, token]);

  // useEffect(() => {
  //   console.log("messages ", messages);
  //   dispatch(setCurrentBoats(messages));
  // }, [messages]);

  return (
    <div>
      <span>The WebSocketsa is currently {connectionStatus}</span>
    </div>
  );
};
