// src/components/SignIn.js
import React, { useState } from "react";
import { signInWithEmailAndPassword } from "firebase/auth";
import { getAuth } from "firebase/auth";
import { app } from "../api/firebase";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useLocation, Navigate } from "react-router-dom";
function SignIn() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const auth = getAuth(app);
  const [email, setEmail] = useState("");
  const user = useSelector((state) => state.user.user);
  const [password, setPassword] = useState("");
  const { from } = location.state || { from: { pathname: "/" } }; // Default to home if no redirect path
  const loginUser = async () => {
    try {
      await signInWithEmailAndPassword(auth, email, password);
      navigate(from.pathname, { replace: true }); // Navigate after login
    } catch (error) {
      console.error("Login error:", error.message);
      alert(error.message);
    }
  };

  if (!user) {
    return (
      <Stack
        spacing={2}
        direction="column"
        justifyContent="center"
        alignItems="center"
        style={{ marginTop: 200 }}
      >
        <img src={"HavocLogo.png"} alt="Havoc Logo" />
        <TextField
          id="outlined-basic"
          label="Email"
          variant="outlined"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          style={{ width: "30%" }}
        />
        <TextField
          id="outlined-basic"
          label="Password"
          variant="outlined"
          type={"password"}
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          style={{ width: "30%" }}
        />

        <Button
          variant="contained"
          style={{ width: "30%" }}
          onClick={loginUser}
        >
          Login
        </Button>
      </Stack>
    );
  } else {
    return <Navigate to={from.pathname} replace />;
  }
}

export default SignIn;
