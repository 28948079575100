import axios from "axios";
import { url, safety_url } from "./url";
// const URL = "http://localhost:8080";
const URL = url;
const SAFETY_URL = safety_url;
async function sendMissionGeofence(obj) {
  console.log("in send geo ", obj);
  const res = await axios.post(`${URL}set_mission_geofence`, obj);
  return res;
}

async function sendMissionAvoidzones(obj) {
  console.log(url, obj);
  const res = await axios.post(`${URL}set_mission_avoidzones`, obj);
  return res;
}

async function sendMissionGoalPoint(obj) {
  const res = await axios.post(`${URL}/set_mission_goal_point`, obj);
  return res;
}

async function sendGotoPt(obj) {
  const res = await axios.post(`${URL}/set_goal_point/${obj.id}`, obj);
  return res;
}

async function sendPlay(obj) {
  const res = await axios.post(`${URL}set_play`, obj);
  return res;
}

async function getHeatmap(obj) {
  const res = await axios.post(`${URL}get_heatmap`, obj);
  return res;
}

async function sendTask(obj) {
  const res = await axios.post(`${URL}set_task`, obj);
  return res.data;
}

async function sendRTL(obj) {
  const boatID = "Rampage01"
  const res = await axios.get(`${SAFETY_URL}send_rtl/${boatID}`);
  return res.data;
}

async function sendDisarm(obj) {

  // const boatID = obj.boatID.split("/")[5];
  const boatID = "Rampage01"
  // console.log("BoatID", boatID)
  const res = await axios.get(`${SAFETY_URL}send_disarm/${boatID}`);
  return res.data;
}

export {
  sendMissionGeofence,
  sendMissionAvoidzones,
  sendMissionGoalPoint,
  sendGotoPt,
  sendPlay,
  sendTask,
  getHeatmap,
  sendRTL,
  sendDisarm,
};
