import React, { useEffect, useState } from "react";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import Box from "@mui/material/Box";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import { useDispatch, useSelector } from "react-redux";
import { setMapMode } from "../data/slices/mapSlice";
import { Typography } from "@mui/material";
import { deleteBoat } from "../data/slices/planSlice";
import { useLocation, useParams } from "react-router-dom";
import { usePlanData } from "../hooks/usePlanData";
import {
  setGeofencePoints,
  setShowTargets,
  setShowTDOALines,
  setTdoaLines,
  setTdoaPoint,
} from "../data/slices/planSlice";
import {
  assignBoat,
  getSectorsFromDb,
  saveSectorAndTeamToDB,
  saveSectorToDb,
  setAssignedSector,
  setAssignedTeam,
  setCurrentSectorPoints,
} from "../data/slices/sectorsSlice";
import { useInterval } from "../api/useInterval";
import {
  doc,
  onSnapshot,
  getFirestore,
  updateDoc,
  getDoc,
} from "firebase/firestore";
import { app } from "../api/firebase";
import {
  sendInterceptorCommand,
  sendSensorTargetData,
  sendStalkCommand,
} from "../api/simTargets";
import {
  calculateCentroid,
  calculateDistance,
  generateHyperbolicLinePoints,
} from "../api/tdoa";
// Create a theme instance.
const darkTheme = createTheme({
  palette: {
    mode: "dark",
  },
});

export default function SectorControl() {
  const dispatch = useDispatch();
  const assignedSector = useSelector((state) => state.sectors.assignedSector);
  const assignedTeam = useSelector((state) => state.sectors.assignedTeam);
  const activeAsset = useSelector((state) => state.map.activeAsset);
  const [selectedSector, setSelectedSector] = useState(null);
  const currentSectorPoints = useSelector(
    (state) => state.sectors.currentSectorPoints
  );
  const sectors = useSelector((state) => state.sectors.sectors);
  const { currentPlan } = useSelector((state) => state.plan);
  const onCreateNewSector = async () => {
    dispatch(setMapMode("create_sector"));
  };

  const onSaveNewSector = async () => {
    // Prompt the user to enter the sector name
    const sectorName = prompt("Please enter the sector name:");

    // Check if the sectorName is not null or empty
    if (sectorName) {
      // Create an object with the sector name and the current sector points
      const sectorData = {
        name: sectorName,
        sector: currentSectorPoints,
      };

      // Dispatch the action to save the sector data to the database
      await dispatch(saveSectorToDb(sectorData));
    } else {
      // Handle the case where no sector name was entered
      console.error("Sector name is required.");
    }
  };

  const onAssignSector = async () => {
    dispatch(setAssignedSector({ plan: currentPlan.id, ...selectedSector }));
  };

  const onCreateNewTeam = async () => {
    // Prompt the user to enter the team name
    const teamName = prompt("Please enter the team name:");

    // Check if the input is not null and not just whitespace
    if (teamName && teamName.trim()) {
      // If there's a valid name, you can proceed with your logic
      console.log("Team name entered:", teamName);
      dispatch(setAssignedTeam(teamName));

      // Add your logic here to handle the creation of the team
      // For example, you might want to save this to a database or state
    } else {
      // If no name was entered, alert the user and don't proceed
      alert("You must enter a team name to proceed!");
    }
  };

  const onAssignBoat = async () => {
    dispatch(
      assignBoat({
        boatID: activeAsset.split("/")[5],
        team: assignedTeam,
        sector: assignedSector.name,
      })
    );
    dispatch(deleteBoat({ boat_string: activeAsset }));
    // setTimeout(window.location.reload(), 000);
  };

  const onSaveToPlan = async () => {
    dispatch(
      saveSectorAndTeamToDB({
        planID: currentPlan.id,
        sectorID: assignedSector.id,
        team: assignedTeam,
      })
    );
    console.log("on save ", {
      planID: currentPlan.id,
      sectorID: assignedSector.id,
      team: assignedTeam,
    });
  };

  useEffect(() => {
    console.log("sectors ", sectors);
  }, [sectors]);

  useEffect(() => {
    console.log("selectedSector ", selectedSector);
    if (selectedSector) {
      dispatch(setCurrentSectorPoints(selectedSector.sector));
    }
  }, [selectedSector]);

  return (
    <ThemeProvider theme={darkTheme}>
      <Box sx={{ minWidth: 120, color: "white" }}>
        <Stack spacing={2}>
          <Button variant="contained" onClick={onCreateNewSector}>
            Create New Sector
          </Button>
          <Button variant="contained" onClick={onSaveNewSector}>
            Save Sector
          </Button>
          <Box sx={{ minWidth: 150, color: "white" }}>
            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-label">
                Select Existing Sector
              </InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                label="Select Existing Sector"
                value={selectedSector || ""}
                onChange={(e) => {
                  setSelectedSector(e.target.value);
                }}
              >
                {sectors.map((sector) => {
                  return (
                    <MenuItem key={sector.id} value={sector}>
                      {sector.name}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          </Box>
          <Button variant="contained" onClick={onAssignSector}>
            Assign Sector
          </Button>
          <Button variant="contained" onClick={onCreateNewTeam}>
            Create New Team
          </Button>
          <Typography style={{ color: "white" }}>
            Assigned Sector: {assignedSector?.name}
          </Typography>
          <Typography style={{ color: "white" }}>
            Assigned Team: {assignedTeam}
          </Typography>
          <Button variant="contained" onClick={onSaveToPlan}>
            Save Sector and Team to Plan
          </Button>
          <Typography style={{ color: "white" }}>
            Selected Boat: {activeAsset?.split("/")[5]}
          </Typography>
          <Button variant="contained" onClick={onAssignBoat}>
            Assign Boat
          </Button>
        </Stack>
      </Box>
    </ThemeProvider>
  );
}
