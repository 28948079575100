import React from "react";
import Paper from "@mui/material/Paper";
import { Button, Stack, Typography } from "@mui/material";
import {
  getFirestore,
  doc,
  getDoc,
  updateDoc,
  setDoc,
} from "firebase/firestore";
import { app } from "../api/firebase";
import { useDispatch, useSelector } from "react-redux";
import { setMapMode } from "../data/slices/mapSlice";
import { createPaddedRectangle } from "../api/utilities";
import {
  setCurrentTerminalArea,
  saveTerminalArea,
  getTerminalAreas,
} from "../data/slices/terminalSlice";

const db = getFirestore(app);

const buttonStyle = {
  width: 250,
};

export default function RightTerminalPanel(props) {
  const dispatch = useDispatch();
  const stagingPoint = useSelector((state) => state.terminal.stagingPoint);
  const terminalPoint = useSelector((state) => state.terminal.terminalPoint);
  const egressPoint = useSelector((state) => state.terminal.egressPoint);
  const onCreateTerminalArea = async () => {
    dispatch(setMapMode("createTerminalArea"));
  };
  const onCreateStagingPoint = async () => {
    dispatch(setMapMode("createStagingPoint"));
  };
  const onCreateTerminalPoint = async () => {
    dispatch(setMapMode("createTerminalPoint"));
  };
  const onCreateEgressPoint = async () => {
    dispatch(setMapMode("createEgressPoint"));
  };
  const onSaveTerminalArea = async () => {
    console.log("terminal area ", [stagingPoint, terminalPoint, egressPoint]);
    const paddedRectangle = createPaddedRectangle([
      stagingPoint,
      terminalPoint,
      egressPoint,
    ]);
    // dispatch(setCurrentTerminalArea(paddedRectangle));
    dispatch(
      saveTerminalArea({
        stagingPoint: stagingPoint,
        terminalPoint: terminalPoint,
        egressPoint: egressPoint,
      })
    );
  };
  return (
    <Paper
      elevation={3}
      style={{
        width: "20%",
        height: 1000,
        backgroundColor: "black",
        marginTop: 30,
      }}
    >
      <Stack
        direction={"column"}
        alignItems={"center"}
        justifyContent={"center"}
        style={{ marginTop: 30 }}
        spacing={3}
      >
        <Typography variant="h6" style={{ color: "white" }}>
          Terminal Plan
        </Typography>

        <Button
          variant="contained"
          style={buttonStyle}
          onClick={onCreateStagingPoint}
        >
          Create Staging Point
        </Button>
        <Button
          variant="contained"
          style={buttonStyle}
          onClick={onCreateTerminalPoint}
        >
          Create Terminal Point
        </Button>
        <Button
          variant="contained"
          style={buttonStyle}
          onClick={onCreateEgressPoint}
        >
          Create Egress Point
        </Button>
        <Button
          variant="contained"
          style={buttonStyle}
          onClick={onSaveTerminalArea}
        >
          Save Terminal Area
        </Button>
      </Stack>
    </Paper>
  );
}
