import React, { useState } from "react";
import { Marker, Source, Layer } from "react-map-gl";
import { useSelector } from "react-redux";
import { useInterval } from "../api/useInterval";
import { getHeatmap } from "../api/plan";
const turf = require("@turf/turf");
function SearchArea() {
  const [heatmapData, setHeatmapData] = useState(null);
  const searchAreaPoints = useSelector((state) => state.plan.searchAreaPts);
  const assignedSector = useSelector((state) => state.sectors.assignedSector);
  const assignedTeam = useSelector((state) => state.sectors.assignedTeam);

  useInterval(async () => {
    let data = {
      team: assignedTeam,
      sector: assignedSector.name,
    };
    try {
      let res = await getHeatmap(data);
      setHeatmapData(res.data);
      console.log("Heatmap data", res.data);
    } catch (e) {
      console.log("Error getting heatmap data", e);
    }
  }, [1000]);

  // Ensure the polygon closes back to the first point
  const closedCoordinates = [...searchAreaPoints, searchAreaPoints[0]].map(
    (point) => [point[0], point[1]]
  );

  var polygon = {
    type: "Feature",
    properties: {},
    geometry: {
      type: "Polygon",
      coordinates: [closedCoordinates],
    },
  };

  // Calculate the bounding box
  var bbox = turf.bbox(polygon);

  // bbox is an array [minLng, minLat, maxLng, maxLat]
  // You can use it to create a bounding box rectangle
  var bboxPolygon = turf.bboxPolygon(bbox);

  const searchAreaData = {
    type: "Feature",
    geometry: {
      type: "Polygon",
      coordinates: [closedCoordinates],
    },
  };

  const bboxData = {
    type: "Feature",
    geometry: {
      type: "Polygon",
      coordinates: [bboxPolygon.geometry.coordinates[0]],
    },
  };

  const GridLayer = ({ data, height, width, bounds }) => {
    const [minLng, minLat] = bounds[0];
    const [maxLng, maxLat] = bounds[1];

    const lngSpan = (maxLng - minLng) / width;
    const latSpan = (maxLat - minLat) / height;

    // const getPolygonForCell = (index) => {
    //   const x = index % width;
    //   const y = Math.floor(index / width);

    //   const baseLng = minLng + x * lngSpan;
    //   const baseLat = minLat + y * latSpan;

    //   // Define the polygon coordinates for this grid cell
    //   return [
    //     [baseLng, baseLat],
    //     [baseLng + lngSpan, baseLat],
    //     [baseLng + lngSpan, baseLat + latSpan],
    //     [baseLng, baseLat + latSpan],
    //     [baseLng, baseLat], // Close the polygon loop
    //   ];
    // };
    const getPolygonForCell = (index) => {
      const x = index % width;
      const y = Math.floor(index / width);

      // Invert the y calculation by starting from the top and going downwards
      const baseLng = minLng + x * lngSpan;
      const baseLat = maxLat - (y + 1) * latSpan; // Subtract to move down

      // Define the polygon coordinates for this grid cell
      return [
        [baseLng, baseLat],
        [baseLng + lngSpan, baseLat],
        [baseLng + lngSpan, baseLat + latSpan],
        [baseLng, baseLat + latSpan],
        [baseLng, baseLat], // Close the polygon loop
      ];
    };

    const features = data.map((value, index) => ({
      type: "Feature",
      properties: {
        // Set fill color to green, and interpolate opacity based on value
        // A value of 0 results in full opacity (1.0), and a value of 1 results in full transparency (0.0)
        fillColor: `rgba(0, 255, 0, ${value})`,
      },
      geometry: {
        type: "Polygon",
        coordinates: [getPolygonForCell(index)],
      },
    }));

    const geoJson = {
      type: "FeatureCollection",
      features,
    };
    return (
      <Source type="geojson" data={geoJson}>
        <Layer
          id="heatmap-grid-layer"
          type="fill"
          paint={{
            "fill-color": ["get", "fillColor"],
            "fill-opacity": 0.1,
          }}
        />
      </Source>
    );
  };

  return (
    <>
      {searchAreaPoints?.map((point, index) => (
        <Marker
          key={index}
          latitude={point[1]}
          longitude={point[0]}
          color="green"
        />
      ))}

      {/* Add the Source and Layer components to render the polygon */}
      <Source id="searchArea" type="geojson" data={searchAreaData}>
        <Layer
          id="searchArea-layer"
          type="line"
          source="searchArea"
          layout={{
            "line-join": "round",
            "line-cap": "round",
          }}
          paint={{
            "line-color": "green",
            "line-width": 5,
          }}
        />
      </Source>

      {heatmapData && (
        <GridLayer
          data={heatmapData?.data}
          height={heatmapData?.height}
          width={heatmapData?.width}
          bounds={[
            [bbox[0], bbox[1]],
            [bbox[2], bbox[3]],
          ]}
        />
      )}
    </>
  );
}

export default SearchArea;
